.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-primary) transparent;
  font-size: ac(18px, 15px);
  line-height: 1.44;
  color: var(--cl-medium-grey);

  @mixin max-sm {
    line-height: 162%;
  }
}

body {
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  color: var(--cl-white);
  background: var(--cl-navy);
  min-width: 320px;
  min-height: calc(100 * var(--vh));
  font-family: var(--font-main);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--cl-primary);
    border-radius: 0;
  }

  [data-simplebar] {
    width: calc(100% + 12px);
    padding-right: 12px;
    max-width: revert;
  }

  .simplebar-scrollbar::before {
    background-color: var(--cl-primary);
    color: var(--cl-primary);
    opacity: 1 !important;
    left: 1px;
    right: 1px;
  }

  .simplebar-track.simplebar-vertical {
    background: rgba(255, 255, 255, 0.2) !important;
    opacity: 1 !important;
    width: 5px;
    border-radius: 5px;
  }
}

[data-aiv^='fade'] {
  opacity: 0 !important;

  transition: all ease 400ms;

  &[data-aiv$='top'] {
    translate: 0 40px !important;
  }
  &[data-aiv$='bottom'] {
    translate: 0 -40px !important;
  }
  &[data-aiv$='left'] {
    translate: 40px 0 !important;
  }
  &[data-aiv$='right'] {
    translate: -40px 0 !important;
  }
  &[data-aiv$='left-top'] {
    translate: 40px 40px !important;
  }
  &[data-aiv$='right-top'] {
    translate: -40px 40px !important;
  }

  &.in-view {
    opacity: 1 !important;

    &[data-aiv$='top'] {
      translate: 0 0 !important;
    }
    &[data-aiv$='bottom'] {
      translate: 0 0 !important;
    }
    &[data-aiv$='left'] {
      translate: 0 0 !important;
    }
    &[data-aiv$='right'] {
      translate: 0 0 !important;
    }
    &[data-aiv$='left-top'] {
      translate: 0 0 !important;
    }
    &[data-aiv$='right-top'] {
      translate: 0 0 !important;
    }
  }
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1282px;
  width: perc(1282);
  padding: 0 20px;

  @media (max-width: 1023px) {
    width: 100%;
  }
}
.cont-wide {
  width: perc(1380);
  max-width: 1380px;
  margin: 0 auto;
  .cont {
    max-width: 1342px;
    width: perc(1342);
  }
  @media (max-width: 1023px) {
    width: calc(100% - 20px);
    .cont {
      width: calc(100% - 10px);
    }
  }
}

.section {
  position: relative;
}

.header,
.footer {
  max-width: 100vw;
}

.section-label {
  text-transform: uppercase;
}

.section__title {
  width: fit-content;
  padding: 8px 18px;
  border: 1px solid var(--cl-fog);
  border-radius: 999px;
  text-transform: uppercase;

  color: var(--cl-fog);

  font-size: 15px;
  font-weight: 500;
  line-height: 1.323;

  @mixin media 769 {
    padding: 7px 17px;
  }
}

.logo {
  transition: 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.06);
  }
}

#bold-credits {
  width: 148px;
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }

  @mixin max-sm {
    width: 159px;
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: ac(250px, 140px) 0 ac(150px, 50px);
  p {
    padding: 0;
    margin-bottom: 10px;
  }
  ul,
  ol {
    padding-left: 20px;
    li {
      p {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }

  a {
    display: inline;
    text-decoration: underline;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
}

.single-page-404 {
  min-height: 100vh;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1,
  p {
    margin-bottom: 15px;
    padding: 0;
  }

  a {
    margin-top: 20px;
  }
}

#map ul {
  li {
    list-style-type: none;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  li::before {
    content: '●';
    position: absolute;
    left: -1.1em;
    font-size: 1.1em;
  }
}

.hero-section-interior {
  padding-top: ac(182px, 130px);
  height: auto;
  min-height: 0;
  .hero__title {
    margin-bottom: ac(100px, 45px);
  }
  .hero-section-thumbnail {
    width: 100%;
    position: relative;
    overflow: hidden;
    min-height: 180px;

    &::before {
      content: '';
      display: block;
      padding-top: 34.72%;
    }
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  .hero-specialism-container {
    top: ac(182px, 130px);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: stretch;

    .hero__title {
      @media (min-width: 360px) {
        h1 {
          font-size: ac(70px, 44px);
        }
      }
    }

    &:before {
      position: absolute;
      inset: 0;
      content: '';
      background: rgba(0, 0, 0, 0.32);
    }
  }
}

.highlight {
  span {
    /* color: var(--cl-primary); */
  }
}

.section-label {
  color: var(--cl-fog);
  font-size: 15px;
  line-height: 1.323;
  padding: 8px 18px;
  border-radius: 31px;
  border: 1px solid var(--cl-fog);
  width: fit-content;
  max-width: 100%;
  margin-bottom: ac(45px, 35px);
  &-dark {
    border-color: var(--cl-navy);
    color: var(--cl-navy);
  }
}

.bg-layer {
  position: absolute;
}
.section-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
  }
}

.mx-layer {
  position: absolute;
  z-index: 2;
  inset: 0;
  background: var(--cl-primary);
  opacity: 0.2;
  mix-blend-mode: soft-light;
}

.line-decor-container {
  position: relative;
  border-bottom: none !important;
}

.line-decor {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    opacity: 0;
    transition: 0.6s;
    transition-delay: 0.1s;
    height: 1px;
    background: var(--cl-white);
  }

  &.animated {
    &:before {
      width: 100%;
      opacity: 1;
    }
  }

  &.second-line-decor {
    &:before {
      left: auto;
      right: 0;
    }
  }
}

.title-animation {
  opacity: 0;

  &.animated {
    opacity: 1;

    .splitting .word {
      animation: slide-in 0.5s cubic-bezier(0.5, 0, 0.5, 1) both;
      animation-delay: calc(100ms * var(--word-index));
      /*clip-path: polygon(110% 0, 110% 100%, 0 100%, 0 0);*/
    }
  }
}

@keyframes slide-in {
  from {
    transform: translateX(0.5em) scaleY(1.2) rotate(0);
    opacity: 0;
    /*clip-path: polygon(110% 80%, 110% 100%, 0 100%, 0 80%);*/
  }
}
