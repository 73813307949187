.about-us-page {
}

.timeline-section {
  padding: ac(120px, 50px) 0 ac(40px, 20px);

  &.with-first {
    padding-top: ac(190px, 120px);
  }

  .title {
    line-height: 1.3;
    margin-bottom: 44px;
    max-width: ac(921px, 768px);
  }

  .section-description {
    &-wr {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      flex-wrap: wrap;
      align-items: flex-end;
      padding-bottom: 30px;

      .swiper-buttons {
        margin-left: auto;
      }
    }

    flex: 1;
    max-width: 712px;
  }

  .section-description {
    margin-bottom: 24px;
    font-size: ac(17px, 15px);
    font-family: var(--font-second);
    line-height: 1.302;
  }
}

.timeline-swiper-wr {
  &-inner {
    background: var(--cl-gray);
    padding: ac(71px, 40px) 0 ac(60px, 40px);
    border-radius: 10px;
    overflow: visible;
    max-width: 1380px;
    width: calc(100% - 20px);
    margin: 0 auto;
    clip-path: inset(-1000px 0 -1000px 0);
    position: relative;
    z-index: 10;

    .swiper {
      margin-left: ac(-27px, 0px);
    }
  }

  .swiper {
    overflow: visible;

    &-wrapper {
    }

    &-slide {
      /* width: ac(424px, 320px);*/
      max-width: 100%;
    }

    @media (max-width: 650px) {
      max-width: 300px;
      margin-left: 0;
      width: 100%;
    }
  }

  .swiper-slide {
    &.animated {
      .timeline-card {
        &:after {
          width: 100%;
          opacity: 1;
        }

        &-dot {
          animation: scaleUp 0.7s ease-out forwards;
        }
      }
    }
  }
}

.timeline-card {
  position: relative;
  /*border-top: 1px solid var(--cl-fog);*/
  padding-right: ac(30px, 20px);

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 0;
    opacity: 0;
    transition: 0.6s;
    content: '';
    background: var(--cl-fog);
    transition-delay: 0.1s;
  }

  &-dot {
    width: 22px;
    height: 22px;
    background: rgba(226, 118, 0, 0.5);
    border-radius: 50%;
    position: relative;
    display: grid;
    place-items: center;
    transform: scale(1) translateY(-50%);
    opacity: 0;
    margin-bottom: 8px;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background: var(--cl-primary);
      border-radius: 50%;
    }
  }

  &-date {
    font-size: ac(25px, 20px);
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 9px;
  }

  &-title {
    font-family: var(--font-second);
    font-weight: 500;
    font-size: 15px;
    line-height: 1.302;
    padding-bottom: 19px;
    position: relative;
    margin-bottom: 19px;

    &::before {
      content: '';
      display: block;
      width: 25px;
      height: 3px;
      background: var(--cl-primary);
      position: absolute;
      bottom: -1px;
      left: 0;
    }
  }

  &-description {
    font-family: var(--font-second);
    font-weight: 400;
    --line-count: 7;
    --fz: ac(17px, 16px);
    --line-height: 1.3017;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));

    p {
    }
  }

  &-item-tooltip {
    position: absolute;
    top: 0;
    left: 0;
    width: 95vw;
    max-width: ac(354px, 290px);
    padding: ac(30px, 20px);
    background: var(--cl-gray);
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;
    z-index: 10;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 0px 0px 30px #06172e;

    &.active {
      opacity: 1;
      pointer-events: auto;
    }

    &.is-second-content {
      transform: translateY(80px);
    }

    @media (min-width: 1430px) {
      max-width: 380px;
    }
  }

  &-item-tooltip-second {
    display: none;
  }

  &-item-tooltip-title {
    font-size: ac(17px, 15px);
    line-height: 125%;
    font-weight: 500;
    position: relative;

    &:after {
      position: absolute;
      left: 0;
      bottom: ac(-16px, -14px);
      transform: translateY(100%);
      height: 3px;
      width: 25px;
      background: var(--cl-primary);
      content: '';
    }

    &:not(:last-child) {
      margin-bottom: ac(38px, 30px);
    }
  }

  &-item-tooltip-text {
    width: calc(100% + 10px);
    padding-right: 10px;
    max-height: calc((ac(18px, 16px) * 1.2) * 9.1);
    font-family: var(--font-second);
    line-height: 1.19;
    font-size: 15px;

    p {
      padding-bottom: 1px;
    }

    .simplebar-track.simplebar-vertical {
      width: ac(3px, 2px);
      background: var(--cl-fog);
      border-radius: 0;
      transition: opacity 0.3s ease;

      .simplebar-scrollbar {
        background: var(--cl-primary);
        border-radius: 0;

        &:before {
          content: none;
        }
      }
    }
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0) translateY(-50%);
    opacity: 0;
  }
  50% {
    transform: scale(1.2) translateY(-50%);
    opacity: 1;
  }
  100% {
    transform: scale(1) translateY(-50%);
    opacity: 1;
  }
}

.tech-section {
  padding: 40px 0;

  &-wrapper {
    display: flex;
    gap: ac(50px, 30px);
    align-items: center;
    border-bottom: 1px solid var(--cl-fog);
    padding-bottom: ac(78px, 50px);
  }

  &-thumbnail {
    flex-shrink: 0;
    width: ac(500px, 280px);
    max-width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 20px;

    &::before {
      content: '';
      display: block;
      padding-top: 54.2%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  &-text {
    flex: 1;
    min-width: 0;
  }

  &-description {
    font-size: ac(25px, 20px);
    line-height: 1.302;
    font-weight: 500;
    font-family: var(--font-second);
    margin-bottom: ac(7px, 0px);
  }

  .title {
    margin-bottom: ac(37px, 30px);
  }
}

.tech-diff-wrapper {
  .diff-section {
    padding-bottom: ac(41px, 20px);
  }

  .values-section .cont {
    padding-top: ac(89px, 20px);
  }
}

.diff-section {
  padding: ac(41px, 20px) 0;

  .title {
    font-size: ac(70px, 30px);
    line-height: 1.187;
    max-width: ac(818px, 300px);
    margin-bottom: ac(45px, 35px);
  }

  &-description {
    max-width: 606px;
    margin-left: auto;
    margin-bottom: ac(80px, 40px);
    font-size: ac(17px, 15px);
    font-family: var(--font-second);
    line-height: 1.302;
  }
}

.diff-swiper-wr {
  .swiper {
    overflow: visible;

    &-buttons {
      justify-content: center;
      width: 100%;
    }

    &-button {
      margin-top: 35px;
    }

    .swiper-slide {
      height: auto;

      .diff-card {
        height: 100%;
      }
    }
  }
}

.diff-card {
  width: 100%;
  background: var(--cl-gray);
  padding: ac(39px, 20px) ac(30px, 20px) ac(30px, 20px);
  min-height: ac(394px, 220px);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  transition: 0.3s;
  cursor: default;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 374px) {
    min-height: 270px;
  }

  &-title {
    font-weight: 500;
    font-size: ac(30px, 20px);
    line-height: 1.1666;
    margin-bottom: ac(21px, 15px);
  }

  &-subtitle {
    font-size: ac(17px, 15px);
    font-weight: 500;
    line-height: 1.302;
    font-family: var(--font-second);
    letter-spacing: 0.01em;

    @media (min-width: 1024px) and (max-width: 1155px) {
      min-height: ac(70px, 35px);
    }
  }

  &-description {
    --line-count: 7;
    --fz: ac(18px, 16px);
    --line-height: 1.28;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    font-family: var(--font-second);

    p {
      padding-bottom: 1px;
    }

    &-wrapper {
      margin-top: auto;
      /*margin-top: ac(57px, 35px);*/
      position: relative;
      padding-top: 21px;
      min-height: 113px;

      &::before {
        content: '';
        display: block;
        width: 25px;
        height: 3px;
        position: absolute;
        left: 0;
        top: 0;
        background: var(--cl-primary);
      }
    }
  }
}

.diff-section {
  .bg-container {
    margin-top: 50px;
    background: var(--cl-navy);
  }
}

.diff-section + .values-section {
  --mt: 196px;
  padding-top: var(--mt);
  margin-top: calc(var(--mt) * -1);
}

.values-section {
  position: relative;
  overflow: hidden;
  background: var(--cl-fog);
  padding: ac(59px, 40px) 0;
  color: var(--cl-navy);

  .bg-layer {
    width: perc(1329);
    max-width: 1329px;
    height: auto;
    top: 0;
    right: 0;
    transform: translate(5.2%, -10.9%);
  }

  .section-label {
    margin-bottom: 33px;
  }

  .title {
    font-size: ac(70px, 30px);
    margin-bottom: ac(33px, 25px);
  }

  &-description {
    font-size: ac(17px, 15px);
    line-height: 1.302;
    font-family: var(--font-second);
  }

  .cont {
    position: relative;
    z-index: 1;
    padding-top: ac(89px, 40px);
  }

  &-intro {
    width: ac(606px, 320px);

    .title {
      color: var(--cl-gray);
    }
  }

  &-options {
    flex-grow: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: ac(20px, 16px);
    padding-top: 20px;

    li {
      display: flex;
      gap: ac(48px, 30px);
      /*background: #33424f2b;
      padding: 30px 20px 30px 30px;
      border-radius: 10px;*/
      justify-content: space-between;
      padding-bottom: ac(20px, 16px);
      position: relative;
      z-index: 1;
      cursor: default;

      &:after {
        background: #33424f2b;
        position: absolute;
        top: ac(-16px, -8px);
        bottom: ac(4px, 2px);
        left: ac(-20px, -10px);
        right: ac(-20px, -10px);
        content: '';
        z-index: -1;
        opacity: 0;
        transition: 0.3s;
        border-radius: 10px;
        transform: scale(0.8);
      }

      &:last-child {
        &:after {
          bottom: ac(-16px, -8px);
        }
      }

      .line-decor {
        transition: 0.3s;
        &:before {
          background-color: var(--cl-navy) !important;
          transition: 0.8s;
          transition-delay: 0.1s;
        }
      }

      &:hover {
        &:after {
          opacity: 1;
          transform: scale(1);
        }

        /*.line-decor {
          opacity: 0;
        }*/
      }

      &:last-child {
        padding-bottom: 0;
        .line-decor {
          display: none;
        }
      }

      h3 {
        font-weight: 500;
        font-size: ac(25px, 22px);
        flex: 0 0 auto;
        width: 165px;
        line-height: 1.2;
      }

      @media (max-width: 1050px) {
        @media (min-width: 993px) {
          flex-direction: column;
          align-items: stretch;
          justify-content: flex-start;
        }
      }
    }
  }

  &-option-text {
    flex: 1;
    min-width: 0;
    font-family: var(--font-second);
    --fz: ac(18px, 16px);
    --lh: 1.4;
    --lc: 4.15;
    font-size: var(--fz);
    line-height: var(--lh);
    max-height: calc(var(--fz) * var(--lh) * var(--lc));

    p {
      word-spacing: -0.5px;
      padding-bottom: 1px;
    }
  }

  &-wrapper {
    display: flex;
    gap: 30px;
  }
}

.gallery-section {
  padding: ac(103px, 45px) 0 30px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .title {
    margin-bottom: ac(46px, 26px);
    font-size: ac(50px, 28px);
    line-height: 1.875;
  }

  .cont {
    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }
}

.marquee-gallery-item {
  margin-left: -320px;
}

.js-marquee {
  &-wrapper {
    display: flex;
  }
}

.life-gallery {
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0 ac(30px, 20px);
  /*min-height: 474px;*/
  height: ac(474px, 320px);
  justify-content: space-between;
  width: auto;
  overflow: hidden;

  &-item {
    position: relative;
    writing-mode: initial;
    height: 100%;
    width: ac(288px, 150px);
    border-radius: 10px;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      background: var(--cl-primary);
      opacity: 0.2;
      mix-blend-mode: soft-light;
      z-index: 1;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }

    &:nth-of-type(9n + 1) {
      width: ac(394px, 180px);
    }

    &:nth-of-type(9n + 2) {
    }

    &:nth-of-type(9n + 3) {
      height: 46.83%;
      /*height: calc(50% - 15px);*/
    }

    &:nth-of-type(9n + 4) {
      /*height: calc(50% - 15px);*/
      height: 46.83%;
    }

    &:nth-of-type(9n + 5) {
      height: 34.17%;
    }

    &:nth-of-type(9n + 6) {
      height: 59.07%;
    }

    &:nth-of-type(9n + 7) {
    }

    &:nth-of-type(9n + 8) {
      height: calc(50% - 15px);
      width: ac(394px, 250px);
    }

    &:nth-of-type(9n + 9) {
      height: calc(50% - 15px);
      width: ac(394px, 250px);
    }
  }
}

.culture-section {
  padding: 30px 0;

  .title {
    max-width: ac(500px, 300px, 992);
    flex: 1;
    min-width: 0;
    line-height: 1.1;
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: ac(77px, 40px);
    border-bottom: 1px solid var(--cl-white);
    gap: 30px;
  }

  &-text {
    flex: 1;
    min-width: 0;
    font-family: var(--font-second);
    padding-top: 16px;
    line-height: 1.302;

    p {
      margin-bottom: 15px;

      &:first-of-type {
        font-size: ac(24px, 20px);
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .btn {
      margin-top: 37px;
      font-family: var(--font-main);
    }
  }
}

.commitment-section {
  padding: ac(51px, 35px) 0 ac(75px, 35px);

  &-wrapper {
    display: flex;
    gap: ac(70px, 30px);
    justify-content: space-between;
    padding-bottom: 32px;
  }

  &-intro {
    flex: 1;
  }

  .title {
    font-size: ac(50px, 28px);
    line-height: 1.065;
    margin-bottom: 45px;
  }

  &-description {
    font-size: ac(25px, 20px);
    font-family: var(--font-second);
    line-height: 1.302;
  }

  &-thumbnail {
    margin-top: 18px;
    width: ac(460px, 280px);
    max-width: 100%;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    align-self: flex-start;

    .commitment-section-thumbnail-item {
      position: absolute;
      inset: 0;
      overflow: hidden;
      border-radius: 10px;
      opacity: 0;
      transition: 0.3s;

      &.active {
        opacity: 1;
      }
    }

    &::before {
      content: '';
      display: block;
      padding-top: 112.39%;
    }

    .brand-logo {
      width: 38px;
      height: auto;
      position: absolute;
      top: 24px;
      right: 25px;
      z-index: 2;
    }

    img:not([class]) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }
}

.commitment-swiper {
  &-controls {
    display: flex;
    gap: 30px ac(90px, 40px);
    border-bottom: 1px solid var(--cl-white);
    margin-bottom: 50px;
  }

  &-tab {
    padding-bottom: 10px;
    margin-bottom: -1px;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    transition: 0.3s;
    font-size: ac(25px, 17px);
    font-weight: 500;
    opacity: 0.5;

    &.active {
      border-color: var(--cl-primary);
      opacity: 1;
    }

    &:hover {
      color: var(--cl-primary);
    }
  }
}

.commitment-swiper-wr {
  padding-top: 30px;

  .swiper {
    &-slide {
      ul {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: ac(30px, 15px);

        li {
          position: relative;
          padding-left: ac(28px, 20px);
          font-size: ac(17px, 15px);
          font-family: var(--font-second);
          line-height: 1.302;

          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            left: 0;
            top: 4px;
            background: var(--cl-primary);
          }
        }
      }
    }
  }
}

.partners-logo-section {
  padding: ac(70px, 30px) 0 ac(130px, 45px);

  .title {
    margin-bottom: ac(43px, 35px);
  }
}

.partners-logo-swiper-wr {
  .swiper {
    overflow: visible;

    &-slide {
      width: fit-content;
      max-width: 100%;
    }

    &-buttons {
      width: 100%;
      justify-content: center;

      @media (min-width: 1300px) {
        display: none;
      }
    }

    &-button {
      margin-top: 35px;
    }
  }
}

.partners-logo-card {
  width: ac(182px, 150px);
  position: relative;
  overflow: hidden;
  border: 1px solid var(--cl-white);
  border-radius: 10px;
  transition: 0.3s;

  &:hover {
    transform: scale(1.05);
  }

  &::before {
    content: '';
    display: block;
    padding-top: 71.42%;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    max-width: calc(100% - 24px);
    height: auto;
    max-height: calc(100% - 24px);
    object-fit: cover;
    transition: 0.3s;
  }
}

@media (max-width: 992px) {
  .values-section {
    &-intro {
      width: 100%;
    }

    &-wrapper {
      flex-direction: column;
    }
  }
}

@media (max-width: 650px) {
  .tech-section-wrapper {
    flex-direction: column-reverse;
  }

  .culture-section-wrapper {
    flex-direction: column;

    .title {
      max-width: 100%;
    }
  }
  .culture-section-text {
    padding-top: 0;
  }
  .commitment-section {
    &-wrapper {
      flex-direction: column;
    }
    &-thumbnail {
      margin: 0 auto;
    }
  }
  .commitment-swiper-wr .swiper-slide ul {
    grid-template-columns: 1fr;
  }
  .timeline-section .section-description-wr {
    flex-direction: column;
  }
  .commitment-swiper-controls {
    flex-direction: column;
    border-bottom: 0;
  }
  .commitment-swiper-tab {
    text-align: center;
    border-bottom: 1px solid var(--cl-white);
  }
}

@media (max-width: 450px) {
  .values-section-options li {
    flex-direction: column;
    gap: 15px;

    h3 {
      width: 100%;

      br {
        display: none;
      }
    }
  }
}
