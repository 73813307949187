:root {
  --cl-primary: #e27600;
  --cl-navy: #06172e;
  --cl-gray: #33424f;
  --cl-fog: #e8e8e8;
  --cl-black: #000000;
  --cl-white: #ffffff;

  --font-main: 'Red Hat Display', sans-serif;
  --font-second: 'DM Sans', sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;

  --vh: calc(1vh);
}
@define-mixin mobile-menu-start-point {
  @media (max-width: 1200px){
    @mixin-content;
  }
}
@define-mixin mobile-menu-end-point {
  @media (min-width: 1201px) {
    @mixin-content;
  }
}