.section__team-member {
  margin-top: ac(180px, 100px);

  .team-member__intro {
    font-family: var(--font-second);
    font-size: ac(18px, 14px);
    font-weight: 400;
    line-height: 1.302;
    color: var(--cl-primary);
  }

  .team-member__title {
    margin-top: ac(25px, 20px);
    font-size: ac(70px, 37px);
    line-height: 0.947;
  }

  .team-member__subtitle {
    margin-top: ac(30px, 15px);
  }

  .team-member__image-wrapper {
    width: calc(100% - ac(60px, 40px));
    margin: ac(50px, 25px) auto 0;
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    &::before {
      content: '';
      display: block;
      padding-top: 43.47%;
      background: var(--cl-primary);
      opacity: 0.2;
      mix-blend-mode: soft-light;
      z-index: 1;
    }
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
      z-index: 0;
    }
  }

  .team-member__content {
    max-width: 820px;
    margin: 0 auto;
    padding-top: ac(100px, 50px);
    padding-bottom: ac(45px, 0px);

    .content-elements {
      margin-bottom: 80px;

      &:last-of-type {
        margin-bottom: 0;
      }

      h2 {
        font-size: ac(30px, 25px);
        font-weight: 500;
        line-height: 1.166;
        margin-bottom: ac(20px, 10px);
      }

      h3 {
        font-size: ac(26px, 24px);
        font-weight: 500;
        line-height: 1.166;
        margin-bottom: ac(20px, 10px);
      }

      h4 {
        font-size: ac(24px, 22px);
        font-weight: 500;
        line-height: 1.166;
        margin-bottom: ac(20px, 10px);
      }

      p {
        font-family: var(--font-second);
        font-size: ac(16px, 13px);
        font-weight: 400;
        line-height: 1.302;
        margin: 0 !important;
        margin-bottom: 15px !important;

        span {
          margin: 0 !important;
          background: transparent !important;
          color: var(--cl-white) !important;
          font: inherit !important;

          b {
            display: block;
            padding-bottom: ac(10px, 5px) !important;
          }
        }

        + h2 {
          padding-top: ac(50px, 20px);
        }

        + h3 {
          padding-top: ac(50px, 20px);
        }

        + h4 {
          padding-top: ac(50px, 20px);
        }

        + ul {
          padding-top: ac(20px, 14px);
        }
      }

      a {
        text-decoration: underline;
        transition: color 0.3s ease;

        &:hover {
          color: var(--cl-primary);
        }
      }

      ul,
      ol {
        display: flex;
        flex-direction: column;
        gap: ac(10px, 5px);
        margin: 0 !important;
        margin-bottom: 10px !important;

        + h2 {
          padding-top: ac(50px, 20px);
        }

        + h3 {
          padding-top: ac(50px, 20px);
        }

        + h4 {
          padding-top: ac(50px, 20px);
        }
      }

      ul {
        li {
          position: relative;
          font-family: var(--font-second);
          font-size: ac(16px, 13px);
          font-weight: 400;
          line-height: 1.302;
          padding-left: ac(28px, 20px);

          span {
            margin: 0 !important;
            background: transparent !important;
            color: var(--cl-white) !important;
            font: inherit !important;
          }

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -60%);
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: var(--cl-primary);
          }
        }
      }

      ol {
        list-style-type: none;
        counter-reset: num;

        li {
          position: relative;
          display: inline-block;
          padding-left: ac(28px, 20px);

          &:before {
            position: absolute;
            color: var(--cl-white);
            top: 0;
            left: 0;
            font: inherit;
            content: counter(num) '.';
            counter-increment: num;
          }
        }
      }

      a {
        display: inline;
        text-decoration: underline;
        transition: 0.3s;
        &:hover {
          color: var(--cl-primary);
        }
      }

      blockquote {
        position: relative;
        padding: ac(105px, 55px) ac(50px, 25px) ac(50px, 25px) ac(50px, 25px);
        border-radius: 10px;
        background-color: var(--cl-gray);
        width: calc(100% + ac(100px, 0px));
        margin-left: ac(-50px, 0px);
        font-size: ac(30px, 20px);
        font-weight: 500;
        line-height: ac(35px, 30px);
        color: var(--cl-white);

        &::before {
          content: '';
          position: absolute;
          left: ac(50px, 25px);
          top: ac(50px, 25px);
          width: ac(64px, 42.67px);
          height: ac(23px, 15.33px);
          background: url(../../assets/img/quotes.svg) no-repeat center/cover;
        }

        &:not(:last-child) {
          margin-bottom: ac(30px, 24px);
        }
      }

      * + blockquote {
        margin-top: ac(30px, 24px);
      }
    }
  }

  .team-member__content__intro {
    display: flex;
    flex-direction: column;
  }

  .team-member__content__subcontent {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .team-member__content__intro-title.heading-highlight {
    span {
      color: var(--cl-primary);
    }
  }

  p {
    font-family: var(--font-second);
    font-size: ac(17px, 15px);
  }

  .team-member__content__subcontent-title {
    font-size: ac(30px, 23px);
  }
}

.team-swiper-wr {
  .swiper {
    overflow: visible;
    padding-top: 20px;
    padding-bottom: 30px;

    @media (max-width: 375px) {
      padding-top: 0;
    }

    &-buttons {
      width: 100%;
      justify-content: center;
      display: flex;
      gap: 30px;
    }
    &-button {
      margin-top: 35px;
    }
    &-slide {
      overflow: visible;
      width: ac(394px, 320px);
      max-width: 100%;
      &-active {
        .team-card {
          transform: translateY(ac(-20px, -10px));
          &-description {
            --line-count: 7;
          }
        }
      }
      &-prev,
      &-next {
        .team-card {
          transform: translateY(ac(30px, 10px));
        }
      }

      &-prev {
        .team-card {
          &-description {
            --line-count: 7;
          }
        }
      }
    }
  }
}

.team__section {
  padding: ac(70px, 35px) 0 ac(120px, 60px);
  overflow-x: hidden;
  .title {
    margin-bottom: ac(70px, 40px);
  }
}

.team-card {
  padding: ac(30px, 20px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: ac(500px, 380px);
  transform: translate(0);
  border-radius: 10px;
  overflow: hidden;
  transition: 0.3s;
  &-bg {
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      background: var(--cl-primary);
      opacity: 0.2;
      mix-blend-mode: soft-light;
      z-index: 1;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 48%,
        rgba(6, 23, 46, 0.8) 100%
      );
      transition: all 0.3s ease;
      z-index: 2;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
      z-index: 0;
    }
  }

  &-name {
    font-weight: 500;
    font-size: ac(30px, 23px);
    line-height: 1.83;
    margin-bottom: 5px;
  }
  &-position {
    font-family: var(--font-second);
    font-size: ac(17px, 15px);
    font-weight: 500;
    line-height: 1.302;
    margin-bottom: 5px;
    letter-spacing: 0.01em;
  }
  &-description {
    --line-count: 0;
    --fz: ac(17.69px, 16px);
    --line-height: 1.302;
    line-height: var(--line-height);
    font-family: var(--font-second);
    font-size: var(--fz);
    font-weight: 400;
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    transition: 0.3s;
    margin-bottom: 6px;
  }
  &-footer {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    max-height: 0;
    transition: 0.3s;
    .btn-icon-only {
      margin-top: 20px;
      margin-left: 0;
    }
  }
  &:hover {
    .team-card {
      &-description {
        --line-count: 7;
        overflow: auto;
      }
      &-footer {
        max-height: 92px;
      }
    }

    /*.team-card-bg {
      &:after {
        opacity: 0.5;
      }
    }*/
  }
}
