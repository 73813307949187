[class^='icon-'],
[class*=' icon-'] {
  display: block;
  width: 22px;
  aspect-ratio: 1;
  background: currentColor;
  mask-image: var(--icon-data);
  mask-repeat: no-repeat;
}

.icon-arrow-down {
  --icon-data: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_731_38)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.5 0L12.5 23L11.5 23L11.5 -8.74228e-08L12.5 0Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.4434 16.455C17.6002 14.7814 20.1761 13.8549 21.9019 13.5097L22.098 14.4903C20.4905 14.8118 18.0664 15.6853 16.0565 17.245C14.0556 18.7977 12.5 20.9986 12.5 24L11.5 24C11.5 20.6014 13.2777 18.1356 15.4434 16.455Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.55645 16.455C6.39972 14.7814 3.8238 13.8549 2.09797 13.5097L1.90186 14.4903C3.50936 14.8118 5.93344 15.6853 7.94338 17.245C9.94431 18.7977 11.4999 20.9986 11.4999 24L12.4999 24C12.4999 20.6014 10.7222 18.1356 8.55645 16.455Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_731_38'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.icon-arrow-left {
  --icon-data: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_731_33)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M24 12.5001L1 12.5001L1 11.5001L24 11.5001L24 12.5001Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.54502 15.4436C9.21863 17.6003 10.1451 20.1762 10.4903 21.902L9.50971 22.0981C9.18821 20.4906 8.3147 18.0666 6.75498 16.0566C5.20226 14.0557 3.00144 12.5001 1.14456e-07 12.5001L1.26381e-07 11.5001C3.39856 11.5001 5.8644 13.2778 7.54502 15.4436Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.54502 8.55657C9.21863 6.39985 10.1451 3.82392 10.4903 2.09809L9.50971 1.90198C9.18821 3.50948 8.3147 5.93356 6.75498 7.9435C5.20226 9.94443 3.00144 11.5 -1.14456e-07 11.5L-1.26381e-07 12.5C3.39856 12.5 5.8644 10.7223 7.54502 8.55657Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_731_33'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.icon-arrow-up {
  --icon-data: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_731_28)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.5 24L11.5 1L12.5 1L12.5 24H11.5Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.55657 7.54502C6.39985 9.21863 3.82392 10.1451 2.09809 10.4903L1.90198 9.50971C3.50948 9.18821 5.93356 8.3147 7.9435 6.75498C9.94443 5.20226 11.5 3.00144 11.5 0L12.5 0C12.5 3.39856 10.7223 5.8644 8.55657 7.54502Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.4436 7.54502C17.6003 9.21863 20.1762 10.1451 21.902 10.4903L22.0981 9.50971C20.4906 9.18821 18.0666 8.3147 16.0566 6.75498C14.0557 5.20226 12.5001 3.00144 12.5001 0L11.5001 0C11.5001 3.39856 13.2778 5.8644 15.4436 7.54502Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_731_28'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.icon-arrow-right {
  --icon-data: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_731_14)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 11.5L23 11.5L23 12.5L-4.37114e-08 12.5L0 11.5Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.455 8.55657C14.7814 6.39985 13.8549 3.82392 13.5097 2.09809L14.4903 1.90198C14.8118 3.50948 15.6853 5.93356 17.245 7.9435C18.7977 9.94443 20.9986 11.5 24 11.5L24 12.5C20.6014 12.5 18.1356 10.7223 16.455 8.55657Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.455 15.4436C14.7814 17.6003 13.8549 20.1762 13.5097 21.902L14.4903 22.0981C14.8118 20.4906 15.6853 18.0666 17.245 16.0566C18.7977 14.0557 20.9986 12.5001 24 12.5001L24 11.5001C20.6014 11.5001 18.1356 13.2778 16.455 15.4436Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_731_14'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.icon-mini-arrow {
  --icon-data: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_731_15)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.1935 9.81507C4.76689 7.93202 1.84282 6.87851 -0.0980225 6.49035L0.0980939 5.50977C2.15725 5.9216 5.23318 7.02809 7.80657 9.02504C9.63426 10.4433 11.2258 12.3267 12 14.7382C12.7742 12.3267 14.3658 10.4433 16.1935 9.02504C18.7669 7.02809 21.8428 5.9216 23.902 5.50977L24.0981 6.49035C22.1573 6.87852 19.2332 7.93202 16.8066 9.81507C14.389 11.6911 12.5 14.3586 12.5 18.0001L11.5 18.0001C11.5 14.3586 9.6111 11.6911 7.1935 9.81507Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_731_15'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.icon-instagram {
  --icon-data: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_731_20)'%3E%3Cpath d='M12 2.163C15.204 2.163 15.584 2.175 16.85 2.233C20.102 2.381 21.621 3.924 21.769 7.152C21.827 8.417 21.838 8.797 21.838 12.001C21.838 15.206 21.826 15.585 21.769 16.85C21.62 20.075 20.105 21.621 16.85 21.769C15.584 21.827 15.206 21.839 12 21.839C8.796 21.839 8.416 21.827 7.151 21.769C3.891 21.62 2.38 20.07 2.232 16.849C2.174 15.584 2.162 15.205 2.162 12C2.162 8.796 2.175 8.417 2.232 7.151C2.381 3.924 3.896 2.38 7.151 2.232C8.417 2.175 8.796 2.163 12 2.163ZM12 0C8.741 0 8.333 0.014 7.053 0.072C2.695 0.272 0.273 2.69 0.073 7.052C0.014 8.333 0 8.741 0 12C0 15.259 0.014 15.668 0.072 16.948C0.272 21.306 2.69 23.728 7.052 23.928C8.333 23.986 8.741 24 12 24C15.259 24 15.668 23.986 16.948 23.928C21.302 23.728 23.73 21.31 23.927 16.948C23.986 15.668 24 15.259 24 12C24 8.741 23.986 8.333 23.928 7.053C23.732 2.699 21.311 0.273 16.949 0.073C15.668 0.014 15.259 0 12 0ZM12 5.838C8.597 5.838 5.838 8.597 5.838 12C5.838 15.403 8.597 18.163 12 18.163C15.403 18.163 18.162 15.404 18.162 12C18.162 8.597 15.403 5.838 12 5.838ZM12 16C9.791 16 8 14.21 8 12C8 9.791 9.791 8 12 8C14.209 8 16 9.791 16 12C16 14.21 14.209 16 12 16ZM18.406 4.155C17.61 4.155 16.965 4.8 16.965 5.595C16.965 6.39 17.61 7.035 18.406 7.035C19.201 7.035 19.845 6.39 19.845 5.595C19.845 4.8 19.201 4.155 18.406 4.155Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_731_20'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.icon-twitter {
  --icon-data: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 4.30007C23.117 4.69055 22.168 4.95353 21.172 5.07207C22.189 4.46543 22.97 3.50416 23.337 2.35861C22.386 2.92043 21.332 3.32884 20.21 3.54899C19.313 2.59569 18.032 2 16.616 2C13.437 2 11.101 4.95453 11.819 8.02162C7.728 7.81741 4.1 5.86499 1.671 2.89752C0.381 5.10196 1.002 7.98576 3.194 9.44609C2.388 9.42019 1.628 9.20005 0.965 8.83247C0.911 11.1047 2.546 13.2304 4.914 13.7036C4.221 13.8908 3.462 13.9347 2.69 13.7872C3.316 15.7357 5.134 17.1532 7.29 17.193C5.22 18.8097 2.612 19.5319 0 19.2251C2.179 20.6167 4.768 21.4286 7.548 21.4286C16.69 21.4286 21.855 13.7374 21.543 6.83921C22.505 6.1469 23.34 5.28325 24 4.30007Z' fill='black'/%3E%3C/svg%3E%0A");
}

.icon-linkedin {
  --icon-data: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_731_21)'%3E%3Cpath d='M19 0H5C2.239 0 0 2.239 0 5V19C0 21.761 2.239 24 5 24H19C21.762 24 24 21.761 24 19V5C24 2.239 21.762 0 19 0ZM8 19H5V8H8V19ZM6.5 6.732C5.534 6.732 4.75 5.942 4.75 4.968C4.75 3.994 5.534 3.204 6.5 3.204C7.466 3.204 8.25 3.994 8.25 4.968C8.25 5.942 7.467 6.732 6.5 6.732ZM20 19H17V13.396C17 10.028 13 10.283 13 13.396V19H10V8H13V9.765C14.396 7.179 20 6.988 20 12.241V19Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_731_21'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.icon-facebook {
  --icon-data: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_731_43)'%3E%3Cpath d='M22.675 0H1.325C0.593 0 0 0.593 0 1.325V22.676C0 23.407 0.593 24 1.325 24H12.82V14.706H9.692V11.084H12.82V8.413C12.82 5.313 14.713 3.625 17.479 3.625C18.804 3.625 19.942 3.724 20.274 3.768V7.008L18.356 7.009C16.852 7.009 16.561 7.724 16.561 8.772V11.085H20.148L19.681 14.707H16.561V24H22.677C23.407 24 24 23.407 24 22.675V1.325C24 0.593 23.407 0 22.675 0Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_731_43'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.icon-email {
  width: 17px;
  --icon-data: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.05078 12.1814V15.3887C6.05078 15.6128 6.19462 15.8113 6.40761 15.8818C6.46086 15.8991 6.51549 15.9074 6.56943 15.9074C6.73125 15.9074 6.88754 15.8313 6.98712 15.6958L8.86325 13.1426L6.05078 12.1814Z' fill='%23E27600'/%3E%3Cpath d='M16.379 0.0961846C16.22 -0.0165356 16.0111 -0.0317493 15.8383 0.0588418L0.278719 8.18438C0.094771 8.2805 -0.0137999 8.4769 0.00141383 8.68367C0.0173191 8.89113 0.154935 9.06816 0.350639 9.13524L4.67619 10.6137L13.8881 2.73716L6.75979 11.3253L14.0091 13.8031C14.0631 13.8211 14.1198 13.8308 14.1765 13.8308C14.2705 13.8308 14.3639 13.8052 14.4462 13.7554C14.5776 13.6752 14.6668 13.5403 14.6896 13.3889L16.5913 0.595473C16.6197 0.401843 16.5381 0.209596 16.379 0.0961846Z' fill='%23E27600'/%3E%3C/svg%3E%0A");
}

.icon-phone {
  width: 15px;
  --icon-data: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.2074 11.0784C13.7541 10.6982 11.0955 9.01468 10.6536 9.09196C10.4462 9.12878 10.2874 9.30564 9.86267 9.81236C9.66623 10.0618 9.4505 10.2954 9.21747 10.511C8.79051 10.4078 8.37722 10.2546 7.98619 10.0546C6.45269 9.30797 5.21381 8.06873 4.46766 6.53501C4.26759 6.14398 4.11439 5.73069 4.01125 5.30373C4.22687 5.0707 4.46046 4.85496 4.70987 4.65852C5.21607 4.23375 5.39345 4.07608 5.43028 3.86758C5.50756 3.42465 3.82246 0.767085 3.44384 0.313784C3.28514 0.126032 3.14095 0 2.95631 0C2.42106 0 0 2.99365 0 3.38161C0 3.41324 0.0518651 6.52982 3.98791 10.5343C7.99241 14.4704 11.109 14.5222 11.1406 14.5222C11.5286 14.5222 14.5222 12.1012 14.5222 11.5659C14.5222 11.3813 14.3962 11.2371 14.2074 11.0784Z' fill='%23E27600'/%3E%3Cpath d='M10.8912 6.74369H11.9285C11.9273 5.64363 11.4897 4.58898 10.7119 3.81112C9.93401 3.03326 8.87936 2.59572 7.7793 2.59448V3.63178C8.60437 3.63261 9.39542 3.96073 9.97884 4.54415C10.5623 5.12757 10.8904 5.91862 10.8912 6.74369Z' fill='%23E27600'/%3E%3Cpath d='M13.4845 6.74247H14.5218C14.5197 4.95489 13.8087 3.24111 12.5447 1.9771C11.2807 0.713085 9.56688 0.00205903 7.7793 0V1.0373C9.29185 1.03909 10.7419 1.64074 11.8115 2.71028C12.881 3.77982 13.4827 5.22991 13.4845 6.74247Z' fill='%23E27600'/%3E%3C/svg%3E%0A");;
}

.icon-star {
  --icon-data: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_736_8)'%3E%3Cpath d='M11.1033 1.81699C11.4701 1.07374 12.5299 1.07374 12.8967 1.81699L15.294 6.67446C15.4397 6.9696 15.7213 7.17417 16.047 7.2215L21.4075 8.00043C22.2277 8.11961 22.5552 9.12759 21.9617 9.70612L18.0828 13.4871C17.8471 13.7169 17.7396 14.0479 17.7952 14.3723L18.7109 19.7111C18.851 20.528 17.9936 21.151 17.26 20.7653L12.4653 18.2446C12.174 18.0915 11.826 18.0915 11.5347 18.2446L6.74005 20.7653C6.00642 21.151 5.14899 20.528 5.2891 19.7111L6.20479 14.3723C6.26043 14.0479 6.15288 13.7169 5.91719 13.4871L2.03827 9.70612C1.44476 9.12759 1.77226 8.11961 2.59249 8.00043L7.95302 7.2215C8.27873 7.17417 8.5603 6.9696 8.70596 6.67446L11.1033 1.81699Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_736_8'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.icon-plus {
  --icon-data: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.3332 7.66797V0H7.66655L7.66655 7.66797H0V8.33462H7.66655L7.66654 15.9996H8.3332V8.33462H15.9996V7.66797H8.3332Z' fill='%23E27600'/%3E%3C/svg%3E%0A");
}
