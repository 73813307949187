.section-contact-us {
  padding-top: ac(180px, 100px);
  padding-bottom: ac(125px, 50px);
  min-height: 100vh;

  h1 {
    font-size: ac(70px, 45px);
    font-weight: 500;
    line-height: 1;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: ac(136px, 45px);

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  .contact-us-wrapper-left {
    flex: 1;
    max-width: ac(500px, 280px);
    padding-top: ac(30px, 20px);
  }

  .contact-us-wrapper-right {
    flex: 1;

    .contact-us-form {
      margin-top: -20px;
      display: flex;
      flex-direction: column;
      gap: ac(20px, 16px);
    }

    input {
      padding: 18px 32px;
      background-color: var(--cl-fog);
    }

    .flex {
      @media (max-width: 1024px) {
        align-self: center;
      }
    }
  }

  &__share {
    margin-top: ac(50px, 25px);
    display: flex;
    flex-direction: column;
    gap: ac(20px, 17px);

    .social-links {
      display: flex;
      align-items: center;
      gap: ac(20px, 14px);

      .social-link {
        a {
          display: flex;
          align-items: center;
          gap: ac(14px, 10px);
          transition: all 0.3s ease;
        }

        &:hover {
          a {
            color: var(--cl-primary);
          }
        }
      }
    }

    p {
      font-size: ac(17px, 14px);
    }

    i {
      color: var(--cl-primary);
    }

    span {
      font-size: ac(17px, 12px);
      font-weight: 500;
      line-height: 1.323;
    }
  }

  .locations-swiper-wr {
    padding-top: 30px;

    .locations-swiper {
      width: 100%;
      height: 100%;

      &-controls {
        display: flex;
        gap: ac(40px, 20px);
        border-bottom: 1px solid var(--cl-fog);
        margin-bottom: ac(24px, 20px);
      }

      &-tab {
        padding-bottom: ac(20px, 15px);
        margin-bottom: -1px;
        border-bottom: 1px solid transparent;
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: ac(30px, 20px);
        font-weight: 500;
        line-height: 1.4;
        opacity: 0.5;
        /*border-bottom: 1px solid var(--cl-white);*/

        span {
          transition: 0.3s;
          border-bottom: 1px solid var(--cl-white);
        }

        &.active {
          opacity: 1;
          border-bottom-color: var(--cl-primary);
          /*border-bottom-color: transparent;*/

          span {
            border-bottom-color: transparent;
          }
        }

        &:hover {
          opacity: 1;
          /*border-bottom-color: var(--cl-primary);*/

          span {
            border-bottom-color: var(--cl-primary);
          }
        }
      }

      .swiper-wrapper {
        display: flex;
      }
    }

    #locations-swiper .swiper-slide {
      ul {
        display: flex;
        flex-direction: column;
        gap: ac(10px, 12px);

        li {
          position: relative;
          font-size: ac(17px, 15px);
          font-family: var(--font-second);
          line-height: 1.302;
          word-spacing: -0.5px;

          a {
            width: fit-content;
            text-decoration: none;
            transition: all 0.3s ease;

            &:hover {
              color: var(--cl-primary);
            }
          }
        }
      }

      p {
        font-size: ac(17px, 15px);
        font-family: var(--font-second);
        line-height: 1.302;
      }

      a {
        font-size: ac(17px, 15px);
        font-family: var(--font-second);
        line-height: 1.302;
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 1024px) {
  .locations-swiper-wr .locations-swiper {
    width: 100%;
  }

  .locations-swiper-wr .swiper-wrapper {
    overflow: hidden;
  }
}
