/*
Styles for popup background and basic styles for fonts
*/

.termsfeed-com---nb .cc-nb-main-container {
  padding-bottom: ac(50px, 24px);
  padding-top: ac(50px, 24px);
}

.termsfeed-com---palette-dark.termsfeed-com---nb {
  border: none;
  border-right: 0;
  border-bottom: 0;
  background: var(--cl-fog);
  border-radius: 8px;
  color: var(--cl-primary);
  font-family: var(--font-main);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.3);

  @mixin min-media 652 {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  @mixin media 651 {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  @mixin media 380 {
    margin-right: 0;
  }

  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    text-transform: uppercase;
  }

  .cc-nb-title,
  .cc-nb-text {
    color: var(--cl-black);
  }

  #cc-nb-text {
    font-size: ac(20px, 18px);
    font-weight: 400;
    line-height: 1.2;
    font-family: var(--font-second);
  }
  #cc-nb-title {
    color: var(--cl-black);
    font-weight: 400;
    font-family: var(--font-md-bt);
    font-size: ac(40px, 24px);
  }
}

.termsfeed-com---nb {
  /*
    Styles for buttons
    */
  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep,
  .cc-cp-foot-button .cc-cp-foot-save {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    width: fit-content;
    z-index: 2;
    font-family: var(--font-main);
    background: var(--cl-primary);
    transition: 0.3s;
    height: ac(62px, 52px);

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 45px;
    flex-shrink: 0;
    width: fit-content;
    padding: 10px 22px;
    border-radius: 999px;
    font-size: ac(17px, 16px);
    font-weight: 500;
    line-height: 1.322;
    min-height: ac(62px, 52px);
    border: 3px solid var(--cl-white);
    text-transform: capitalize !important;

    border-color: transparent !important;
    background: var(--cl-primary);
    color: var(--cl-white);

    &:after {
      width: 24px;
      display: block;
      aspect-ratio: 1;
      background: currentColor;
      mask-image: var(--icon-data);
      mask-repeat: no-repeat;
      --icon-data: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_731_14)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 11.5L23 11.5L23 12.5L-4.37114e-08 12.5L0 11.5Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.455 8.55657C14.7814 6.39985 13.8549 3.82392 13.5097 2.09809L14.4903 1.90198C14.8118 3.50948 15.6853 5.93356 17.245 7.9435C18.7977 9.94443 20.9986 11.5 24 11.5L24 12.5C20.6014 12.5 18.1356 10.7223 16.455 8.55657Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.455 15.4436C14.7814 17.6003 13.8549 20.1762 13.5097 21.902L14.4903 22.0981C14.8118 20.4906 15.6853 18.0666 17.245 16.0566C18.7977 14.0557 20.9986 12.5001 24 12.5001L24 11.5001C20.6014 11.5001 18.1356 13.2778 16.455 15.4436Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_731_14'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      color: var(--cl-white);
      content: '';
      transition: 0.3s;
    }

    &:hover {
      background: transparent;
      border-color: var(--cl-primary) !important;
      color: var(--cl-primary);

      &:after {
        translate: 25% 0;
        color: var(--cl-primary);
      }
    }
  }

  .cc-nb-buttons-container {
    display: flex !important;
    flex-wrap: wrap;
    grid-row-gap: ac(20px, 12px);
    grid-column-gap: ac(30px, 16px);
  }

  .cc-nb-changep {
    background: var(--cl-gray) !important;
    color: var(--cl-white) !important;

    &:after {
      color: var(--cl-white) !important;
    }

    &:hover {
      background: var(--cl-white) !important;
      color: var(--cl-navy) !important;
      border-color: var(--cl-navy) !important;

      &:after {
        color: var(--cl-navy) !important;
      }
    }
  }
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry a {
  color: var(--cl-gray);
  transition: all 0.3s ease;
  text-transform: none;
  font: inherit;

  &:hover {
    color: var(--cl-primary);
    text-decoration-color: transparent;
  }
}

/*
Styles for popup with settings
*/
.termsfeed-com---palette-dark {
  .cc-pc-container {
    font-family: var(--font-main) !important;
    border: none;
    border-radius: 0;
    overflow: hidden;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.3);
  }

  .cc-pc-head,
  .cc-cp-body-content,
  .cc-cp-foot {
    background: var(--cl-fog) !important;
    border-top-color: var(--cl-primary) !important;
    border-bottom-color: var(--cl-primary) !important;
  }

  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-body-tabs {
    background-color: var(--cl-fog);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 3px;
    padding: 3px;
  }

  .cc-cp-body-tabs-item {
    background: var(--cl-primary);
    transition: all 0.3s ease;
    opacity: 1;
    border: none !important;
    border-radius: 8px !important;

    &:hover {
      opacity: 0.8;
      .cc-cp-body-tabs-item-link {
        color: var(--cl-fog) !important;
      }
    }
  }

  .cc-cp-body-tabs-item-link {
    color: var(--cl-fog);
    transition: all 0.3s ease;

    &:focus {
      box-shadow: none;
    }
  }

  .cc-cp-body-tabs-item[active='true'] {
    background: var(--cl-gray) !important;

    .cc-cp-body-tabs-item-link {
      color: var(--cl-fog);
    }

    &:hover {
      opacity: 1;

      .cc-cp-body-tabs-item-link {
        color: var(--cl-fog) !important;
      }
    }
  }

  .cc-pc-head-lang {
    select {
      color: var(--cl-primary);
      padding: 5px;
      background: var(--cl-fog);
      cursor: pointer;
      border: 1px solid var(--cl-primary);

      &:focus {
        box-shadow: none;
      }

      option {
        font-size: ac(18px, 16px);
        font-weight: 400;
        line-height: ac(32px, 28px);
        font-style: normal;
        font-family: var(--font-main);
        cursor: pointer;
      }
    }
  }

  .cc-pc-head-close {
    color: var(--cl-primary);
    transition: all 0.3s ease;

    &:hover {
      color: var(--cl-medium-grey);
      transform: scale(1.1);
    }

    &:focus {
      border: 0;
    }
  }
}

/*
Styles for the lower part of the popup with settings
*/
.termsfeed-com---pc-dialog {
  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-foot {
    padding: 10px 0;
    flex-wrap: wrap;
  }

  .cc-cp-foot-byline {
    a {
      color: var(--cl-gray);
      text-decoration: underline;
      transition: all 0.3s ease;
      display: inline-block;

      &:hover {
        color: var(--cl-primary);
        text-decoration-color: transparent;
      }
    }
  }

  .cc-cp-foot-button .cc-cp-foot-save {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    width: fit-content;
    z-index: 2;
    font-family: var(--font-main);
    background: var(--cl-primary);
    transition: 0.3s;
    height: ac(62px, 52px);

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 45px;
    flex-shrink: 0;
    width: fit-content;
    padding: 10px 22px;
    border-radius: 999px;
    font-size: ac(17px, 16px);
    font-weight: 500;
    line-height: 1.322;
    min-height: ac(62px, 52px);
    border: 3px solid var(--cl-white);
    text-transform: capitalize !important;

    border-color: transparent !important;
    background: var(--cl-primary);
    color: var(--cl-white);

    &:after {
      width: 24px;
      display: block;
      aspect-ratio: 1;
      background: currentColor;
      mask-image: var(--icon-data);
      mask-repeat: no-repeat;
      --icon-data: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_731_14)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 11.5L23 11.5L23 12.5L-4.37114e-08 12.5L0 11.5Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.455 8.55657C14.7814 6.39985 13.8549 3.82392 13.5097 2.09809L14.4903 1.90198C14.8118 3.50948 15.6853 5.93356 17.245 7.9435C18.7977 9.94443 20.9986 11.5 24 11.5L24 12.5C20.6014 12.5 18.1356 10.7223 16.455 8.55657Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.455 15.4436C14.7814 17.6003 13.8549 20.1762 13.5097 21.902L14.4903 22.0981C14.8118 20.4906 15.6853 18.0666 17.245 16.0566C18.7977 14.0557 20.9986 12.5001 24 12.5001L24 11.5001C20.6014 11.5001 18.1356 13.2778 16.455 15.4436Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_731_14'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      color: var(--cl-white);
      content: '';
      transition: 0.3s;
    }

    &:hover {
      background: transparent;
      border-color: var(--cl-primary) !important;
      color: var(--cl-primary);

      &:after {
        translate: 25% 0;
        color: var(--cl-primary);
      }
    }

    @mixin media 530 {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry-text {
  a {
    display: inline-block;
  }
}

.termsfeed-com---palette-dark {
  .cc-cp-body-content-entry-text {
    color: var(--cl-black);
    font-weight: 500;
  }

  .cc-cp-body-content-entry-title {
    color: var(--cl-black);
    font-weight: 400;
    font-family: var(--font-md-bt);
  }

  .cc-pc-head-title-text {
    color: var(--cl-black);
    font-weight: 500;
  }

  .cc-pc-head-title-headline {
    color: var(--cl-black);
    font-weight: 400;
    font-family: var(--font-md-bt);
  }

  .cc-cp-foot-byline {
    color: var(--cl-black);
    font-weight: 500;
  }
}

.cc-custom-checkbox {
  label {
    font-weight: 500;
  }
}

.termsfeed-com---pc-dialog input[type='checkbox'].cc-custom-checkbox + label {
  color: var(--cl-black);

  &:after,
  &:before {
    border-color: var(--cl-primary);
  }
}

.termsfeed-com---pc-dialog
  input[type='checkbox'].cc-custom-checkbox:checked
  + label::before {
  background: var(--cl-primary);
}

/*
Styles for mobile adaptation
*/
@mixin media 651 {
  .termsfeed-com---nb-simple {
    max-width: 90%;
  }

  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    margin-top: 0 !important;
    max-width: 100%;
  }

  .termsfeed-com---nb .cc-nb-main-container {
    padding: 2rem;
  }
}

@mixin media 425 {
  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    min-width: auto;
    text-align: center !important;
    justify-content: center;
    font-size: 16px;
  }
}

@mixin media 380 {
  .termsfeed-com---nb-simple {
    max-width: 100%;
  }

  .termsfeed-com---pc-dialog .cc-pc-head-lang {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    justify-content: space-between;

    select {
      max-width: 200px;
    }
  }
}
