.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: ac(19px, 13px) 0;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  /*background: var(--cl-navy);*/

  &.scrolled {
    background: var(--cl-navy);
    padding: 16px 0;
  }

  &.active {
    .header__mob-menu-wrap {
      opacity: 1;
      translate: 0;
      scale: 1;
      visibility: visible;
    }

    .burger {
      .burger__inner {
        span {
          opacity: 0;
        }

        &::before {
          top: 50%;
          rotate: 45deg;
          translate: 0 -50%;
        }

        &::after {
          bottom: 50%;
          rotate: -45deg;
          translate: 0 50%;
        }
      }
    }

    .header__mob-menu-dropshadow {
      opacity: 1;
      visibility: visible;
    }
  }
}

.header__logo {
  z-index: 1;
  display: inline-flex;
  width: fit-content;

  img {
    width: ac(202px, 162px);
  }

  transition: scale ease 250ms;

  &:hover {
    scale: 1.05;
  }
}

.header__navbar {
  display: flex;
  align-items: center;
}

.header__menu-wrap {
  display: flex;
  align-items: center;

  margin-left: auto;

  @mixin media 551 {
    margin-right: 4px;
  }
}

.menu {
  display: flex;
  align-items: center;
  gap: ac(30px, 15px);

  margin-right: ac(42px, 14px);

  @mixin media 1025 {
    display: none;
  }
}

.menu__link {
  font-size: ac(17px, 15px);
  font-weight: 500;
  line-height: 1.322;

  transition: all ease 250ms;

  &:hover {
    color: var(--cl-primary);
  }

  &-active {
    color: var(--cl-primary);
  }
}

.header__mob-menu-wrap {
  position: fixed;
  top: var(--header-height);
  right: 0;
  z-index: 1;

  display: none;
  grid-template-rows: auto repeat(0, 1fr);
  /* flex-direction: column; */

  width: 100vw;
  max-width: 320px;
  height: calc(calc(100 * var(--vh)) - var(--header-height));

  padding: 24px 24px 40px;

  background: transparent;

  translate: 100% 0;
  opacity: 0;
  visibility: hidden;

  transition: all ease 400ms;

  @mixin media 1025 {
    display: grid;
  }

  @mixin media 551 {
    max-width: unset;

    translate: 0 50%;
    /* scale: 0.5; */
  }

  .select {
    max-width: fit-content;
  }
}

.header__mob-menu-nav {
  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: end;

  height: 100%;

  margin-top: auto;
}

.header__mob-menu-list {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 24px;

  margin-bottom: 48px;

  @mixin media 551 {
    align-items: center;
  }
}

.header__mob-menu-link {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.32;

  transition: color ease 250ms;

  &.active {
    color: var(--cl-primary);
    font-size: 26px;
  }

  /*&:hover {
    color: var(--cl-primary);
  }*/

  &.dropdown {
    &:hover {
      .header__mob-menu-link-dropdown-title {
        &::after {
          background: var(--cl-primary);
        }
      }
    }

    .header__mob-menu-link-dropdown-title {
      display: flex;
      align-items: center;
      gap: 7px;

      &::after {
        content: '';

        display: block;
        width: 15px;
        aspect-ratio: 1;
        background: var(--cl-gray);
        mask-repeat: no-repeat;
        mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 13.8315L19.422 6.45495C19.5604 6.31261 19.7258 6.19909 19.9087 6.12099C20.0917 6.04289 20.2884 6.00178 20.4875 6.00006C20.6866 5.99834 20.8841 6.03604 21.0684 6.11097C21.2526 6.1859 21.42 6.29655 21.5608 6.43647C21.7016 6.57639 21.8128 6.74278 21.8882 6.92592C21.9637 7.10906 22.0016 7.3053 21.9999 7.50317C21.9981 7.70104 21.9568 7.89659 21.8782 8.07839C21.7996 8.2602 21.6854 8.42464 21.5422 8.56212L13.0601 16.9923C12.7789 17.2716 12.3976 17.4286 12 17.4286C11.6024 17.4286 11.2211 17.2716 10.9399 16.9923L2.45775 8.56212C2.31454 8.42464 2.20031 8.2602 2.12173 8.07839C2.04315 7.89659 2.00179 7.70104 2.00006 7.50317C1.99833 7.3053 2.03626 7.10906 2.11166 6.92592C2.18705 6.74278 2.29838 6.57639 2.43917 6.43647C2.57995 6.29655 2.74736 6.1859 2.93163 6.11097C3.1159 6.03604 3.31335 5.99834 3.51244 6.00006C3.71153 6.00178 3.90828 6.04289 4.09121 6.12099C4.27415 6.19909 4.43959 6.31261 4.57791 6.45495L12 13.8315Z' fill='black'/%3E%3C/svg%3E%0A");

        transition: all ease 250ms;
      }
    }

    .header__mob-menu-dropdown-wrap {
      display: flex;
      flex-direction: column;
      align-items: start;

      padding-top: 10px;
      max-height: 0;
      opacity: 0;
      visibility: hidden;

      transition: all ease 250ms;
    }

    .header__mob-menu-dropdown-link {
      position: relative;

      width: 100%;

      padding: 5px 15px;

      color: var(--cl-black);

      font-size: 20px;
      text-align: start;

      transition: all ease 250ms;

      &:hover {
        color: var(--cl-blue);
      }

      &::before {
        content: '';

        position: absolute;
        left: 0;
        top: 50%;
        translate: 0 -50%;

        width: 10px;
        height: 2px;

        background: currentColor;
      }
    }

    &.active {
      .header__mob-menu-link-dropdown-title {
        &::after {
          rotate: 180deg;
        }
      }

      .header__mob-menu-dropdown-wrap {
        max-height: 500px;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.header__mob-menu-btns {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.burger {
  position: relative;
  z-index: 1;

  display: none;

  width: 56px;
  height: 56px;

  border-radius: 999px;

  cursor: pointer;

  background: var(--cl-fog);

  &__inner {
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 27px;
    height: 20px;

    &::before,
    &::after,
    span {
      content: '';
      position: absolute;
      left: 0;

      width: 100%;
      height: 2px;
      border-radius: 99px;

      background: var(--cl-primary);

      transition: all ease 250ms;
    }

    span {
      top: 50%;
      translate: 0 -50%;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  @mixin media 1025 {
    display: block;
  }
}

.header__mob-menu-dropshadow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  opacity: 0;
  visibility: hidden;

  transition: all ease 400ms;

  &::before {
    content: '';
    position: absolute;
    inset: 0;

    opacity: 0.82;

    background: #06172e;
  }
  &::after {
    content: '';
    position: absolute;
    inset: 0;

    opacity: 0.2;

    background: var(--cl-primary);

    mix-blend-mode: soft-light;
  }
}

.header__mob-menu-dropshadow-bg {
  width: 100%;
  height: 100%;

  object-fit: cover;
  z-index: -1;
}

.header__btn {
  @mixin media 1025 {
    display: none;
  }
}

.header__mob-social-list {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 23px;

  margin-bottom: 48px;

  @mixin media 551 {
    justify-content: center;
  }
}

.header__mob-social-item {
  display: block;

  color: var(--cl-primary);

  transition: scale ease 250ms;

  &:hover {
    scale: 1.2;
  }
}

.header__mob-btn {
  width: 100%;
  justify-content: center;
}
