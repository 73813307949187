.communities-section {
  padding: ac(120px, 50px) 0 ac(42px, 20px);

  .title {
    max-width: 1030px;
    font-size: ac(40px, 26px);
    max-width: 1100px;

    @media (max-width: 1024px) {
      max-width: 800px;
    }
  }
}

.sectors-section {
  padding: ac(40px, 20px) 0;

  &-wrapper {
    .swiper-buttons {
      width: 100%;
      justify-content: flex-end;
      padding-bottom: ac(58px, 35px);
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.sectors-swiper-wr {
  border-top: 1px solid var(--cl-white);
  border-bottom: 1px solid var(--cl-white);
  padding-top: 30px;
  display: flex;
  gap: 35px;
  padding-bottom: ac(77px, 40px);

  .swiper {
    flex: 1;
    min-width: 0;
  }
}

.sectors-swiper-tabs {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: ac(495px, 280px);
  flex-shrink: 0;
  align-items: flex-start;
}

.sectors-swiper-tab-item {
  font-size: ac(25px, 20px);
  font-weight: 300;
  line-height: 1.2;
  opacity: 0.3;
  padding-left: ac(30px, 20px);
  position: relative;
  cursor: pointer;
  transition: 0.3s;

  &::before {
    content: '';
    display: block;
    width: 5px;
    height: 0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 2.5px;
    transition: 0.3s;
  }

  &:hover {
    color: var(--cl-primary);
    opacity: 0.9;
  }

  &.active {
    opacity: 1;
    color: var(--cl-primary);
    font-size: ac(25px, 17px);

    &::before {
      background: var(--cl-primary);
      height: 100%;
    }
  }
}

.sector-info-card {
  font-size: 17px;
  font-family: var(--font-second);
  line-height: 1.302;
  font-weight: 300;

  h3,
  h2 {
    font-size: ac(45px, 27px);
    font-weight: 500;
    font-family: var(--font-main);
    margin-bottom: ac(30px, 20px);
    line-height: 1.166;
  }

  h3 {
    font-size: ac(30px, 23px);
  }

  p {
    font-weight: 300;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ul {
    display: flex;
    gap: 10px 20px;
    flex-wrap: wrap;

    li {
      font-size: 16px;
      line-height: 1.301;
      display: flex;
      gap: 5px;

      &::before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        background: var(--cl-primary);
        border-radius: 50%;
        align-self: flex-start;
        position: relative;
        top: calc((16px * 1.301) / 2);
        transform: translateY(-50%);
      }

      &.hidden {
        display: none;
      }
    }
  }

  &-types {
    padding-top: 30px;

    & > span {
      display: flex;
      font-size: 17px;
      font-weight: 500;
      font-family: var(--font-main);
      margin-bottom: 18px;
    }

    .border-btn {
      margin-top: 30px;
    }
  }

  .btn {
    margin-top: 50px;

    &.btn-show-hide {
      &:not(.is-visible) {
        display: none !important;
      }
    }
  }

  .buttons {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: ac(20px, 10px);

    .border-btn,
    .btn {
      margin: 0 !important;
    }
  }
}

.diff-section.diff-section-specialisms {
  position: relative;
  padding-bottom: ac(140px, 45px);

  .bg-layer {
    position: absolute;
    width: perc(1068);
    max-width: 1068px;
    top: 160px;
    left: 50%;
    transform: translate(-50%, 0);
    mix-blend-mode: screen;
    z-index: -1;

    &::before {
      content: '';
      display: block;
      background: var(--cl-primary);
      padding-top: 100%;
      position: relative;
      z-index: 1;
      mix-blend-mode: color;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: contain;
      z-index: 0;
    }
  }

  .title {
    max-width: ac(1030px, 370);
    margin-bottom: 44px;
    font-size: ac(65px, 27px);
    line-height: 1.055;
  }

  .diff-section-description {
    font-size: ac(30px, 20px);
    line-height: 1.1666;
    max-width: 712px;
    margin-left: 0;
    margin-bottom: ac(77px, 35px);
    font-family: var(--font-main);
    font-weight: 500;
  }

  .diff-card {
    background: rgba(51, 66, 79, 0.8);
    padding-bottom: ac(56px, 20px);
    padding-top: ac(30px, 20px);

    &-title {
    }

    &-description {
      &-wrapper {
        margin-top: auto;
      }
    }
  }
}

.job-types-section {
  &-wrapper {
    background: var(--cl-gray);
    display: flex;
    border-radius: 10px;
  }

  &-thumbnail {
    width: ac(569px, 280px);
    max-width: 100%;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    &::before {
      content: '';
      display: block;
      padding-top: 112.39%;
    }

    .brand-logo {
      width: 38px;
      height: auto;
      position: absolute;
      top: 24px;
      right: 25px;
      z-index: 2;
    }

    img:not([class]) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  &-text {
    padding: ac(60px, 30px) ac(68px, 20px) ac(58px, 30px) ac(69px, 20px);
    min-width: 0;
    flex: 1;

    .swiper {
      overflow: visible !important;
    }
  }

  .section-label {
    margin-bottom: 40px;
  }

  .title {
    margin-bottom: 50px;
    line-height: 1.1;
  }
}

.jobs-types-swiper-controls {
  display: flex;
  padding-bottom: 30px;
}

.jobs-types-swiper-tab {
  flex: 1;
  padding-right: 10px;
  display: flex;
  gap: 18px;
  padding-bottom: ac(30px, 17px);
  border-bottom: 1px solid var(--cl-white);
  opacity: 0.5;
  cursor: pointer;
  transition: 0.3s;

  &.active {
    opacity: 1;
    border-color: var(--cl-primary);
    pointer-events: none;
  }

  &:hover {
    color: var(--cl-primary);
    opacity: 0.9;
  }

  span {
    color: var(--cl-primary);
    font-size: ac(18px, 16px);
    line-height: 1.302;
    display: block;
    font-family: var(--font-second);
    margin-top: ac(5px, 0px);
  }

  h3 {
    font-weight: 500;
    font-size: ac(30px, 20px);
  }
}

.jobs-types-info-card {
  --line-count: 11.6;
  --fz: 16px;
  --line-height: 1.302;
  font-family: var(--font-second);
  font-size: var(--fz);
  line-height: var(--line-height);
  max-height: calc(var(--fz) * var(--line-height) * var(--line-count));

  p {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.case-studies-section {
  padding: ac(202px, 50px) 0 40px;

  .title {
    margin-bottom: ac(44px, 35px);
    font-size: ac(50px, 28px);
    line-height: 1.055;
  }

  &-description {
    font-size: ac(17px, 15px);
    line-height: 1.302;
    margin-bottom: 50px;
    font-family: var(--font-second);
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: ac(40px, 20px) ac(29px, 20px);
    border-bottom: 1px solid var(--cl-white);
    padding-bottom: ac(80px, 40px);
  }
}

.case-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  &__content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: ac(30px, 20px);

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      height: 0;
      opacity: 0;
      transition: 0.5s;
      content: '';
      background: var(--cl-white);
      width: 1px;
    }

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  .btn {
    margin-left: ac(30px, 20px);
  }

  &.in-view {
    .case-item__content {
      &:before {
        height: 100%;
        opacity: 1;
      }
    }
  }

  &__title {
    font-size: ac(30px, 22px);

    line-height: normal;
    font-weight: 600;
    transition: 0.3s;

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  &__text {
    font-family: var(--font-second);
    font-size: ac(17px, 15px);
    line-height: 1.302;
    margin-left: 20px;
    transition: 0.3s;

    @media (max-width: 551px) {
      margin-left: 0;
    }
  }

  &:hover {
    .case-item {
      &__title {
        color: var(--cl-primary);
      }

      &__text {
        color: var(--cl-primary);
      }
    }
  }
}

.case-studies-item {
  display: flex;
  gap: 19px;

  & > span {
    font-family: var(--font-second);
    color: var(--cl-primary);
    font-size: ac(18px, 16px);
    display: block;
    margin-top: ac(5px, 2px);
    min-width: 18px;
  }

  &-content {
    a {
      font-family: var(--font-main);
      font-size: ac(25px, 20px);
      font-weight: 500;
      line-height: 1.4;
    }

    .case-studies-link {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .case-studies-link-text {
      margin-bottom: 20px;
    }

    .case-studies-link:hover {
      span {
        transition: all 0.3s ease;
        color: var(--cl-primary);
      }

      .btn-icon-only {
        border-color: var(--cl-primary);
        color: var(--cl-primary);
      }
    }
  }

  .btn-icon-only {
    margin-left: 0;
    margin-top: auto;
  }
}

.ts-section {
  padding: ac(40px, 20px) 0 ac(130px, 45px);

  &-wrapper {
    display: flex;
    gap: 30px;
  }

  &-caption {
    flex-shrink: 0;
    width: ac(712px, 320px);
  }

  .title {
    font-size: ac(60px, 28px);
    line-height: 1.055;
    margin-bottom: ac(44px, 35px);
  }

  &-description {
    font-size: ac(30px, 30px);
    line-height: 1.166;
    max-width: 577px;
  }
}

.ts-swiper-wr {
  min-width: 0;
  flex: 1;
  padding-top: ac(117px, 0px);

  .swiper {
    padding-top: 30px;

    &-buttons {
      width: 100%;
      justify-content: flex-end;
    }

    &-slide {
      display: flex;
      height: auto;
    }
  }

  .reviews-card {
    width: 100%;

    &__scroll {
      color: var(--cl-navy);
      --line-count: 10;
    }
  }
}

.register-section {
  position: relative;
  padding: ac(151px, 45px) 0 ac(88px, 40px);
  overflow: hidden;

  @media (min-width: 769px) {
    min-height: ac(897px, 618px);
  }

  .bg-layer {
    z-index: 0;
    width: perc(1362);
    max-width: 1362px;
    min-width: 600px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-2%, -20.3%);
  }

  .cont {
    position: relative;
    z-index: 1;
  }

  .section-bg {
    filter: blur(1.5px);

    &::after {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      background: linear-gradient(
        270.06deg,
        #000000 0.06%,
        rgba(0, 0, 0, 0.4) 99.95%
      );
      opacity: 0.5;
    }
  }

  .title {
    font-size: ac(70px, 27px);
    line-height: 1.055;
    margin-bottom: ac(44px, 35px);
    max-width: 544px;
  }

  &-description {
    font-size: ac(17px, 15px);
    line-height: 1.28;
    max-width: 496px;
    font-family: var(--font-second);
    color: var(--cl-fog);
  }

  &-caption {
    flex: 1;
    min-width: 0;
  }

  &-form {
    width: ac(450px, 280px);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 21px;
    padding-top: ac(20px, 0px);

    .btn {
      margin-top: 8px;
    }
  }

  &-wrapper {
    display: flex;
    gap: 30px;
    padding-right: ac(50px, 0px);
  }
}

@media (max-width: 750px) {
  .sectors-swiper-wr {
    flex-direction: column;

    .swiper {
      min-height: 0;
      width: 100%;
    }
  }

  .sectors-swiper-tabs {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
  }

  .job-types-section {
    &-wrapper {
      flex-direction: column;
      padding-top: ac(30px, 20px, 375, 750);
    }

    &-thumbnail {
      margin: 0 auto;
      width: 400px;
      max-width: calc(100% - 40px);
    }
  }

  .case-studies-section-grid {
    grid-template-columns: 1fr;
  }

  .ts-section {
    &-wrapper {
      flex-direction: column;
    }

    &-caption {
      width: 100%;
    }

    .ts-swiper-wr {
      width: 400px;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .register-section {
    &-wrapper {
      flex-direction: column;
    }

    &-form {
      width: 100%;
    }
  }
}

@media (max-width: 550px) {
  .jobs-types-swiper-controls {
    flex-direction: column;
    gap: 20px;
  }
}
