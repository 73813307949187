.benefits {
  padding-top: ac(60px, 40px);
  padding-bottom: ac(57px, 40px);
  background: var(--cl-fog);
  overflow: hidden;
  position: relative;

  &__bg {
    @mixin aspect-ratio 947, 1006;
    width: ac(800px, 400px);
    max-width: 95%;
    position: absolute;
    bottom: ac(-300px, -100px);
    right: 0;
    filter: drop-shadow(0px 0px 30px rgba(6, 23, 46, 0.14));
    overflow: visible;
    mask-image: none;
    z-index: -1;
    opacity: 0.7;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 2;
  }

  &__title {
    color: var(--cl-navy);
    border-color: var(--cl-navy);
    &:not(:last-child) {
      margin-bottom: ac(45px, 35px);
    }
  }

  &__big-text {
    font-size: ac(50px, 28px);
    font-weight: 500;
    line-height: 105.56%;
    color: var(--cl-gray);

    &:not(:last-child) {
      margin-bottom: ac(44px, 30px);
    }
  }

  &__text {
    width: 100%;
    max-width: ac(929px, 650px);
    font-size: 17px;
    font-family: var(--font-second);
    line-height: 130%;
    font-weight: 400;
    color: var(--cl-navy);
    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }
  }

  &__buttons {
    &:not(:last-child) {
      margin-bottom: ac(60px, 30px);
    }

    .btn.navy {
      background-color: var(--cl-gray);
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: ac(30px, 16px);
    grid-row-gap: ac(40px, 20px);

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: 551px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__item {
    color: var(--cl-navy);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    user-select: none;

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: ac(-14px, -10px);
      width: 1px;
      background: var(--cl-navy);
      content: '';
      height: 0;
      transition: height 1s ease; 
    }
  
    &.in-view:before {
      height: 100%; 
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #33424f2b;
      border-radius: 10px;
      z-index: -1;
      opacity: 0;
      transform: scale(0.8);
      transition: 0.3s;
    }

    &:hover {
      &:after {
        opacity: 1;
        transform: scale(1.04);
      }
    }

    .benefits-card_btn {
      background-color: var(--cl-navy);
      color: var(--cl-white);
      padding: 5px;
      border-radius: 50%;
      border: 1px solid var(--cl-navy);
      transition: 0.3s;

      .icon-plus {
        position: relative;
        left: 3px;
        top: 3px;
      }

      &:hover {
        background-color: var(--cl-white);
        color: var(--cl-primary);
      }
    }

    .benefits-card__hidden-btn {
      position: relative;
      cursor: pointer;
      width: auto;
      margin-top: 15px;

      @media (min-width: 1025px) {
        &:hover {
          .benefits-card__hidden-content {
            opacity: 1;
            pointer-events: auto;
          }

          .benefits-card_btn {
            background-color: var(--cl-white);
            color: var(--cl-primary);
          }
        }
      }

      @media (max-width: 1024px) {
        &.active {
          .benefits-card__hidden-content {
            opacity: 1;
            pointer-events: auto;
          }

          .benefits-card_btn {
            background-color: var(--cl-white);
            color: var(--cl-primary);
          }
        }
      }
    }

    &:nth-child(2n) {
      @media (max-width: 1350px) {
        @media (min-width: 1025px) {
          .benefits-card__hidden-content {
            left: auto;
            right: 16px;
          }
        }
      }
    }

    .benefits-card__hidden-content {
      padding: ac(30px, 24px) ac(30px, 20px);
      border-radius: 10px;
      box-shadow: 0px 0px 30px #00060e;
      width: ac(380px, 290px);
      position: absolute;
      left: 16px;
      bottom: 16px;
      opacity: 0;
      pointer-events: none;
      z-index: 1;
      transition: all 0.3s ease;
      background: var(--cl-navy);
    }

    .benefits-card__hidden-text {
      width: calc(100% + 10px);
      padding-right: 10px;
      font-size: ac(18px, 16px);
      line-height: 1.2;
      font-family: var(--font-second);
      color: var(--cl-white);
      max-height: calc((ac(18px, 16px) * 1.21) * 10.2);

      p {
        padding-bottom: 2px;
      }

      .simplebar-track.simplebar-vertical {
        width: 4px;
        background: var(--pp-light-gray);
        border-radius: 2px;

        .simplebar-scrollbar {
          background: var(--cl-primary);
          border-radius: 2px;

          &:before {
            content: none;
          }
        }
      }
    }
  }

  &__item-title {
    font-size: ac(30px, 24px);
    line-height: 1.4;
    font-weight: 500;
    position: relative;

    /*&:after {
      position: absolute;
      left: 0;
      bottom: ac(-16px, -14px);
      transform: translateY(100%);
      height: 3px;
      width: 25px;
      background: var(--cl-primary);
      content: '';
    }*/

    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  &__item-text {
    font-family: var(--font-second);
    line-height: normal;
    width: calc(100% + 10px);
    padding-right: 10px;
    max-height: calc((ac(18px, 16px) * 1.44) * 8.1);

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--cl-white);
      border-radius: 4px;

      .simplebar-scrollbar {
        background: var(--cl-primary);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    p {
      padding-bottom: 1px;
    }
  }
}

@keyframes growLine {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
