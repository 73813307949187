h1,
h2,
h3,
h4 {
  font-weight: 500;
  line-height: 130%;
  color: var(--cl-grey);
}

h1 {
  font-size: ac(60px, 34px);
  line-height: normal;

  @mixin max-sm {
    line-height: 129%;
  }
}

h2 {
  font-size: ac(40px, 24px);
  line-height: 1.3;

  @mixin max-sm {
    line-height: 140%;
  }

  &.subtitle {
    font-size: ac(34px, 22px);
    line-height: 140%;
  }
}

h3 {
  font-size: ac(24px, 22px);
  line-height: normal;
  font-weight: 600;
}

h4 {
  font-size: ac(20px, 18px);
  line-height: 140%;
  font-weight: 400;

  @mixin max-sm {
    line-height: 155%;
  }
}

h5 {
  font-size: ac(28px, 20px);
  font-weight: 500;
  line-height: 1.5;
}

h6 {
  font-size: ac(24px, 18px);
  font-weight: 500;

  line-height: 2;
}

a {
  font-size: ac(20px, 16px);
  font-weight: 500;
  display: inline-block;
  line-height: 100%;
}

.content-element {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  }

  h2 {
    &:not(:last-child) {
      margin-bottom: ac(30px, 20px);
    }
  }

  h4,
  h3 {
    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  ol,
  ul {
    li {
      list-style-position: inside;
      display: inline-flex;
    }
  }

  ol {
    list-style: decimal;

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  ul {
    list-style: disc;

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  p {
    /*Not Design*/
    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  &.privacy-type {
    h2 {
      margin-bottom: ac(45px, 22px);
      span {
        color: var(--cl-primary);
      }
      & + h3 {
        margin-top: ac(40px, 30px);
      }
    }
    h3 {
      margin-top: ac(40px, 30px);
      margin-bottom: ac(20px, 18px);
      font-size: ac(29px, 23px);
      font-weight: 500;
    }
    p {
      margin-top: 0;
      margin-bottom: ac(24px, 16px);
      font-family: var(--font-second);
      font-size: ac(20px, 14px);
      font-weight: 400;
      line-height: 1.302;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
    a {
      display: inline-block;
      text-decoration: underline;
      transition: 0.3s;
      &:hover {
        color: var(--cl-primary);
      }
    }

    ul,
    ol {
      display: flex;
      flex-direction: column;
      gap: ac(10px, 5px);
      margin-bottom: ac(24px, 16px);
    }

    li {
      position: relative;
      font-family: var(--font-second);
      font-size: ac(20px, 14px);
      font-weight: 400;
      line-height: 1.302;
      padding-left: ac(28px, 20px);
      list-style: none;
      display: inline-block;

      p {
        margin-top: 0 !important;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -60%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--cl-primary);
      }
    }

    ol {
      li {
        list-style: decimal inside;
        padding-left: ac(15px, 10px);

        p {
          margin: 0 !important;
        }
      }
    }

    ol li::before {
      content: none;
    }

    blockquote {
      position: relative;
      padding: ac(105px, 55px) ac(50px, 25px) ac(50px, 25px) ac(50px, 25px);
      border-radius: 10px;
      background-color: var(--cl-primary);
      width: calc(100% + ac(100px, 0px));
      margin-left: ac(-50px, 0px);
      font-size: ac(30px, 20px);
      font-weight: 500;
      line-height: ac(35px, 30px);
      color: var(--cl-navy);

      &::before {
        content: '';
        position: absolute;
        left: ac(50px, 25px);
        top: ac(50px, 25px);
        width: ac(64px, 42.67px);
        height: ac(23px, 15.33px);
        background: url(../../assets/img/quotes.svg) no-repeat center/cover;
      }

      &:not(:last-child) {
        margin-bottom: ac(24px, 16px);
      }
    }
  }
}

.dark-bg {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: var(--cl-white);
  }
}
