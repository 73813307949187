.input {
  padding: 15px ac(29px, 21px);
  border-radius: 999px;
  border: 3px solid transparent;

  background: var(--cl-gray);

  font-family: var(--font-second);
  font-size: ac(20px, 16px);

  transition: border ease 250ms;
  @mixin transparent-bg-input white;

  &:focus {
    border-color: var(--cl-primary);
  }

  @media (max-width: 551px) {
    padding: 10px 15px;
  }
}

.select {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 6px;

  min-width: ac(248px, 200px);

  .choices {
    height: 100%;

    &::after {
      border: none;

      display: block;
      width: 20px;
      height: 20px;
      aspect-ratio: 1;
      background: var(--cl-white);
      translate: 0 -40%;
      margin-top: 0;
      right: ac(32px, 26px);

      mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_731_15)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.1935 9.81507C4.76689 7.93202 1.84282 6.87851 -0.0980225 6.49035L0.0980939 5.50977C2.15725 5.9216 5.23318 7.02809 7.80657 9.02504C9.63426 10.4433 11.2258 12.3267 12 14.7382C12.7742 12.3267 14.3658 10.4433 16.1935 9.02504C18.7669 7.02809 21.8428 5.9216 23.902 5.50977L24.0981 6.49035C22.1573 6.87852 19.2332 7.93202 16.8066 9.81507C14.389 11.6911 12.5 14.3586 12.5 18.0001L11.5 18.0001C11.5 14.3586 9.6111 11.6911 7.1935 9.81507Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_731_15'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      mask-repeat: no-repeat;

      transition: all ease 250ms;

      @media (max-width: 551px) {
        translate: 0 -80%;
      }
    }

    &.is-open {
      &::after {
        margin-top: 0;
        rotate: 180deg;
      }
    }

    &.is-flipped {
      .choices__list--dropdown {
        bottom: calc(100% + 10px);
      }
    }
  }

  .choices__inner {
    display: flex;
    align-items: center;

    height: 100%;

    padding: ac(18px, 12px) ac(32px, 25px) !important;
    border-radius: 999px !important;
    border: none;

    background: var(--cl-gray);

    @media (max-width: 551px) {
      height: 47px;
    }
  }

  .choices__list--single {
    padding: 0 20px 0 0;
  }

  .choices__list--dropdown {
    top: calc(100% + 10px);

    background: var(--cl-gray);
    border: none;

    border-radius: 15px !important;
  }

  .choices__item {
    font-family: var(--font-second);
    font-size: ac(20px, 16px);
    font-weight: 400;

    color: var(--cl-white);

    transition: all ease 250ms;

    &.is-highlighted {
      background-color: var(--cl-primary) !important;
    }

    &--selectable {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .choices__placeholder {
    opacity: 1;
  }

  .choices__list--dropdown .choices__item--selectable,
  .choices__list[aria-expanded] .choices__item--selectable {
    padding-right: 20px;
  }
}

.input-wr {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: var(--font-second);
  span {
    font-size: ac(16px, 14px);
    line-height: 1.301;
  }
  input,
  textarea {
    padding: 16px;
    border-radius: 31px;
    color: var(--cl-navy);
    background-color: var(--cl-fog);
    font-size: ac(20px, 18px);
    @mixin transparent-bg-input var(--cl-navy);

    &::placeholder {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      opacity: 0.5;
    }
  }

  textarea {
    overflow-y: auto;
    resize: none;
    scrollbar-width: thin;
    scrollbar-color: var(--cl-primary) transparent;
    clip-path: inset(0 0 0 0 round 31px);
  }
}
