.join {
  overflow: hidden;

  padding: ac(150px, 96.5px) 0;

  height: 100vh;
  min-height: 620px;

  @mixin media 1025 {
    max-height: 700px;
  }

  @mixin media 769 {
    height: unset;
    min-height: unset;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  &::before {
    background: var(--cl-primary);
    opacity: 0.2;
    mix-blend-mode: soft-light;
  }
  &::after {
    background: linear-gradient(
      270.06deg,
      #000000 0.06%,
      rgba(0, 0, 0, 0.4) 99.95%
    );
    opacity: 0.5;
  }

  .cont {
    position: relative;
    z-index: 2;
  }
}

.join__bg {
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;

  @mixin media 1025 {
    object-position: left 90% top 50%;
  }

  @mixin media 551 {
    object-position: left 29% top 50%;
  }
}

.join__decor {
  position: absolute;
  top: 47%;
  left: 51%;
  translate: -50% -50%;
  z-index: 2;

  width: 94.6vw;
  max-width: 1560px;
  min-width: 709px;
  min-height: 110%;

  @mixin media 1281 {
    width: 185vw;
  }

  @mixin media 769 {
    width: 94.6vw;
  }

  @mixin media 551 {
    left: 72%;
    top: 48%;
  }
}

.join__content-wrap {
  max-width: 500px;
  margin-left: auto;
}

.join__title {
  margin-bottom: ac(41px, 16px);

  font-size: ac(70px, 38px);
  font-weight: 500;
  line-height: 1.06;

  @mixin media 769 {
    line-height: 1.16;
  }
}

.join__description {
  margin-bottom: ac(40px, 21px);

  * {
    font-family: var(--font-second);
    font-size: ac(17px, 15px);
    line-height: 1.3;
  }
}

.join__btn {
  @mixin media 451 {
    width: 100%;
  }
}
