.section__news {
  padding-top: ac(185px, 100px);

  .news__button-load-more {
    display: block;
    width: max-content;
    margin: 0 auto;
    margin-bottom: ac(130px, 75px);
    font-size: ac(17px, 14px);
    font-weight: 500;
    line-height: 1.322;
    padding: 10px 25px;
    border: 1px solid var(--cl-white);
    border-radius: 31px;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: var(--cl-white);
      color: var(--cl-navy);
    }
  }

  .section__news__top {
    h1 {
      font-size: ac(70px, 37px);
      line-height: 0.947;
      margin-bottom: ac(65px, 35px);
    }

    .categories {
      margin-bottom: ac(45px, 30px);
    }

    .categories ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px 8px;
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    .categories ul li {
      position: relative;
    }

    .categories ul input[type='radio'] {
      display: none;
    }

    .categories ul label {
      font-size: ac(17px, 14px);
      font-weight: 500;
      line-height: 1.322;
      padding: 13px 25px;
      border: 1px solid var(--cl-white);
      border-radius: 31px;
      background-color: transparent;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;
      display: inline-flex;
    }

    .categories ul input[type='radio']:checked + label {
      background-color: var(--cl-fog);
      color: var(--cl-navy);
    }

    .categories ul label:hover {
      background-color: var(--cl-fog);
      color: var(--cl-navy);
    }
  }

  .section__news__content {
    margin-bottom: ac(68px, 35px);
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.news__card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 408px;

  &__img-wrapper {
    .mx-layer,
    img {
      border-radius: 10px;
    }
  }

  &__hidden-layer {
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 10px;
    background-color: var(--cl-primary);
    padding: ac(30px, 15px);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    z-index: 11;
    overflow: hidden;

    h2 {
      font-size: ac(50px, 27px);
      font-weight: 500;
      line-height: 1.1;
      position: relative;
      z-index: 1;
    }

    &__descr {
      font-family: var(--font-second);
      font-weight: 400;
      margin-top: ac(31px, 16px);
      --line-count: 4;
      --fz: ac(18px, 14px);
      --line-height: 1.302;
      line-height: var(--line-height);
      font-size: var(--fz);
      max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
      position: relative;
      z-index: 1;

      p {
        padding-bottom: 1px;
      }

      .simplebar-track.simplebar-vertical {
        background: var(--cl-white);
        .simplebar-scrollbar {
          background: var(--cl-navy);

          &:before {
            background: var(--cl-navy);
          }
        }
      }
    }

    .btn {
      position: absolute;
      right: ac(30px, 15px);
      bottom: ac(30px, 15px);
    }
  }

  &__hidden-decor {
    @mixin aspect-ratio 572, 608;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    transition: 0.3s;

    &:after {
      position: absolute;
      inset: 0;
      border-radius: 10px;
      background: var(--cl-navy);
      content: '';
      opacity: 0.5;
    }
  }

  &:hover .news__card__hidden-layer {
    opacity: 1;
    pointer-events: all;
  }

  &:hover .news__card__hidden-decor {
    width: 572px;
    left: -35px;
    top: -59px;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: var(--font-second);
    font-size: ac(16px, 13px);
    font-weight: 400;
    line-height: 1.302;
    color: var(--cl-fog);
  }

  &__img-wrapper {
    position: relative;
    overflow: hidden;
    max-height: 250px;
    border-radius: 10px;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }

    .btn-icon-only {
      position: absolute;
      bottom: ac(20px, 12px);
      right: ac(20px, 12px);
      z-index: 10;
    }
  }

  &__info {
    --line-count: 3;
    --fz: ac(25px, 17px);
    --line-height: 1.4;
    line-height: var(--line-height);
    font-size: var(--fz);
    @mixin max-line-length 3;
  }

  &--hide {
    display: none;
  }
}
