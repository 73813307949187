.marquee-section {
  overflow: hidden;

  margin-bottom: -1px;

  background: linear-gradient(var(--cl-navy) 50%, var(--cl-fog) 50%);

  .cont {
    display: flex;
    max-width: 100vw;
    width: 100vw;
  }

  &.join-team-page {
    padding-top: ac(140px, 60px);
    padding-bottom: ac(134px, 40px);
    background: var(--cl-navy);
  }
}

.marquee-section__top-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-column-gap: 20px;
  grid-row-gap: 30px;

  &.cont {
    margin: 0 auto;
    max-width: 1282px;
    width: perc(1282);
    padding: 0 20px;

    @media (max-width: 1023px) {
      width: 100%;
    }
  }

  &:not(:last-child) {
    margin-bottom: ac(62px, 40px);
  }
}

.marquee-section__title {
  font-size: ac(70px, 26px);
  font-weight: 500;
  line-height: 105.56%;
}

.marquee-section__socials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.marquee-section__social {
  display: flex;
  justify-content: center;
  align-items: center;
  --size: ac(63px, 50px);
  width: var(--size);
  height: var(--size);
  background: var(--cl-primary);
  border: 2px solid var(--cl-primary);
  transition: background-color 0.3s ease;
  cursor: pointer;
  border-radius: ac(10px, 8px);

  i {
    --size: ac(35px, 28px);
    width: var(--size);
    height: var(--size);
    font-size: ac(35px, 28px);
    color: var(--cl-white);
    transition: color 0.3s ease;
  }

  &:hover {
    background-color: var(--cl-navy);

    i {
      color: var(--cl-fog);
    }
  }
}

.marquee__content {
  display: flex;
  gap: ac(30px, 16px);
  padding-bottom: ac(50px, 24px);
}

.marquee__item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  width: ac(398px, 164px);

  &:nth-child(odd) {
    translate: 0 ac(50px, 24px);
  }

  @mixin media 769 {
    width: 164px;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;

    background: var(--cl-primary);
    opacity: 0.2;
    mix-blend-mode: soft-light;
  }
}

.marquee__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
