.team-section {
  padding-top: ac(140px, 60px);
  padding-bottom: ac(124px, 40px);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
  }

  &__decor {
    @mixin aspect-ratio 94.73, 101.16;
    top: 0;
    right: 0;
    width: ac(94px, 64px);
    position: absolute;
    pointer-events: none;

    img {
      object-fit: contain;
    }

    @media (max-width: 640px) {
      opacity: 0.5;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: ac(818px, 640px);

    &:not(:last-child) {
      margin-bottom: ac(70px, 30px);
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(45px, 35px);
    }
  }

  &__big-text {
    font-size: ac(58px, 26px);
    font-weight: 500;
    line-height: 106.25%;

    span {
      /* color: var(--cl-primary); */
    }

    &:not(:last-child) {
      margin-bottom: ac(44px, 30px);
    }
  }

  &__text {
    width: 100%;
    font-size: ac(30px, 20px);
    font-family: var(--font-main);
    font-weight: 500;
    line-height: 116.67%;
  }

  &__slider {
    width: 100%;
    max-width: 100%;

    .swiper-buttons {
      .swiper-button {
        margin-top: 0;
        margin-bottom: ac(50px, 30px);
      }
    }
  }

  &.join-team-page {
    .team-card-bg {
      &:after {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 48%,
          rgba(6, 23, 46, 0.8) 100%
        );
        transition: all 0.3s ease;
      }
    }


    /*.team-card {
      &:hover {
        .team-card-bg {
          &:after {
            opacity: 0.5;
          }
        }
      }
    }*/
  }
}
