.clients-page {
  .job-details-page__ready {
    .ready__title {
      margin-bottom: ac(25px, 15px);
      max-width: 800px;
      width: 100%;
      font-size: ac(50px, 25px);
      line-height: 1.1;
    }

    .ready__description {
      max-width: 700px;
      width: 100%;
      font-family: var(--font-second);
      font-size: 17px;
      font-weight: 400;
      line-height: 1.302;
    }

    @media (max-width: 1280px) {
      .ready__title {
        max-width: 600px;
      }

      .ready__description {
        max-width: 600px;
      }
    }

    @media (max-width: 950px) {
      .ready__title {
        max-width: 450px;
      }

      .ready__description {
        max-width: 450px;
      }
    }
  }

  .job-details-page__ready--attention {
    .ready__content-wrapper__left {
      max-width: 900px;

      @media (max-width: 1024px) {
        max-width: 450px;
      }
    }
  }
}

.solutions-section {
  margin-top: ac(60px, 30px);
  padding: ac(60px, 35px) 0 0;
  margin-bottom: 30px;
  overflow: hidden;

  &.with-first {
    margin-top: ac(95px, 90px);
    padding-top: ac(95px, 30px);
  }

  .cont {
    position: relative;
    z-index: 1;
  }

  .solutions-section-decor {
    @mixin aspect-ratio 94.73, 101.16;
    position: absolute;
    z-index: -1;
    width: 80%;
    right: -10px;
    bottom: auto;

    top: ac(-150px, -50px);

    @media (max-width: 551px) {
      right: 0;
      width: 100%;
      top: 0;
    }
  }

  .title {
    font-size: ac(70px, 27px);
    font-weight: 500;
    line-height: 1.187;
    margin-bottom: ac(44px, 35px);
  }

  &-wrapper {
    max-width: 835px;
  }

  &-description {
    max-width: 712px;
    font-size: ac(17px, 15px);
    font-family: var(--font-second);
    line-height: 1.302;
    margin-bottom: ac(60px, 35px);
  }
}

.solutions-swiper-wr {
  padding-bottom: ac(78px, 40px);
  border-bottom: 1px solid var(--cl-white);

  .swiper {
    overflow: visible;
    &-buttons {
      width: 100%;
      justify-content: center;
    }
    &-button {
      margin-top: 35px;
    }
  }
}

.solutions-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--cl-fog);
  border-radius: 10px;
  padding: 30px;
  /*min-height: 358px;*/

  &-header {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: ac(30px, 24px);

    h3 {
      font-size: ac(25px, 17px);
      font-family: var(--font-main);
      font-weight: 500;
      line-height: 1.1666;
    }

    .toggle-btn {
      display: flex !important;
    }
  }

  &-text {
    position: relative;
    padding-top: 22px;

    &::before {
      content: '';
      display: block;
      background: var(--cl-primary);
      width: 25px;
      height: 3px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &-description {
    --line-count: 0;
    --fz: 18px;
    --line-height: 1.275;
    font-family: var(--font-second);
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    overflow: hidden;
    transition: 0.5s;
    letter-spacing: -0.05px;
    /*@mixin max-line-length 1;*/
  }

  &.active {
    .solutions-card {
      &-description {
        max-height: revert;
        display: block !important;
      }
    }
  }
}

.focus-section {
  padding: ac(45px, 20px) 0 ac(75px, 40px);

  .title {
    margin-bottom: ac(37px, 30px);
    width: 100%;
    text-align: center;
  }

  &-description {
    font-size: ac(25px, 20px);
    font-family: var(--font-second);
    line-height: 1.302;
    /*max-width: 924px;*/
    margin-bottom: ac(39px, 25px);
    width: 100%;
    text-align: center;
  }
}

.brand-list-container {
  ul {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 12px ac(60px, 30px);
    font-size: ac(17px, 15px);
    font-family: var(--font-second);
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @media (max-width: 1390px) {
      font-size: 18px;
    }

    @media (max-width: 1270px) {
      font-size: 16px;
    }

    @media (max-width: 1150px) {
      font-size: 18px;
    }

    @media (max-width: 420px) {
      grid-template-columns: 1fr;
    }

    li {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      &::before {
        content: url('../../assets/img/list-marker.svg');
        display: flex;
        transform: translateY(2px);
      }

      display: flex;
      gap: 15px;

      @media (max-width: 1150px) {
        justify-content: flex-start;
        text-align: left;
      }
    }
  }
}

.talent-section {
  padding: ac(54px, 20px) 0 ac(140px, 40px);

  .title {
    font-size: ac(70px, 27px);
    line-height: 1.0555;
    margin-bottom: ac(44px, 35px);
  }

  &-description {
    font-size: ac(17px, 15px);
    font-family: var(--font-second);
    line-height: 1.302;
    max-width: 712px;
    margin-bottom: ac(60px, 35px);
  }

  &-grid {
    background: var(--cl-gray);
    padding: ac(60px, 30px) ac(60px, 20px);
    display: grid;
    grid-gap: 50px 30px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    transform: translate(0);

    img {
      position: absolute;
      right: 0;
      top: 0;
      width: perc(947, 1242);
      height: auto;
      z-index: -1;
      transform: translate(-6.7%, -16.3%);
    }

    @media (min-width: 751px) {
      .talent-section-item {
        &:nth-child(odd) {
          &:before {
            transition-delay: 0.3s;
          }
        }
      }
    }
  }

  &-item {
    padding-left: 20px;
    /*border-left: 1px solid var(--cl-white);*/
    position: relative;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      background: var(--cl-white);
      height: 0;
      opacity: 0;
      transition: 0.5s;
      content: '';
    }

    &.animated {
      &:before {
        height: 100%;
        opacity: 1;
      }
    }

    @media (max-width: 750px) {
      max-width: ac(670px, 240px, 320, 750);
    }

    &-title {
      font-size: ac(40px, 24px);
      font-weight: 500;
      line-height: 1.1;
      margin-bottom: 20px;
      font-family: var(--font-main);
    }

    &-description {
      --fz: ac(20px, 18px);
      --lh: 1.302;
      --lc: 4.1;
      font-size: var(--fz);
      line-height: var(--lh);
      font-weight: 400;
      font-family: var(--font-second);
      max-height: calc(var(--fz) * var(--lh) * var(--lc));

      p {
        padding-bottom: 1px;
      }
    }
  }
}

.services-section {
  padding: ac(60px, 30px) 0;
  background: var(--cl-gray);

  .title {
    font-size: ac(70px, 27px);
    line-height: 1.0555;
    margin-bottom: ac(100px, 35px);
  }
}

.services-list {
  &-item {
    padding: 20px 0 19px;
    font-family: var(--font-second);
    display: grid;
    grid-template-columns: ac(192px, 80px) 1fr;
    gap: 0 20px;
    border-top: 1px solid var(--cl-fog);
    cursor: pointer;
    position: relative;

    &:last-of-type {
      border-bottom: 1px solid var(--cl-fog);
    }

    &-number {
      font-size: ac(18px, 16px);
      line-height: 1.302;
      color: var(--cl-primary);
      position: relative;
      top: 5px;
    }

    &-title {
      font-size: 30px;
      font-weight: 300;
      font-family: var(--font-main);
      transition: 0.3s;
      transform-origin: left;
    }

    &-plus {
      position: absolute;
      right: 0;
      top: 40px;
      transform: translateY(-50%);
      z-index: 1;
    }

    &-content-wrapper {
      grid-column: 2/-1;
      display: none;
    }

    &-content {
      display: grid;
      grid-template-columns: 1fr ac(394px, 280px);
      gap: 0 ac(52px, 20px);
      padding-top: 28px;
      line-height: 1.28;

      p {
        margin-bottom: 15px;

        &:first-of-type {
          font-size: ac(17px, 15px);
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 9px;
        /*margin-top: -7px;*/

        li {
          position: relative;
          display: flex;
          gap: 18px;
          font-size: 16px;

          &::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            /*border-radius: 50%;*/
            background: var(--cl-primary);
            transform: translateY(50%);
            flex-shrink: 0;

            mask-image: url('../../assets/static/check-mask.svg');
            mask-size: 100% 100%;
            mask-repeat: no-repeat;
          }
        }
      }
    }

    &-content-aside {
      &[data-simplebar] {
        max-height: 240px;
        padding-right: 10px;
        width: 100%;

        p,
        li {
          padding-bottom: 1px;
        }
      }
    }

    &.active {
      .services-list-item {
        &-title {
          transform: scaleX(1.05);
        }

        &-content-wrapper {
          display: block;
        }

        &-plus {
          .icon-plus {
            position: relative;
            mask-image: none;
            background: transparent;
            background-image: none;
            transition: none;

            &:before {
              height: 1.5px;
              width: 100%;
              top: 50%;
              transform: translateY(-50%);
              position: absolute;
              content: '';
              background: var(--cl-primary);
            }
          }
        }
      }
    }

    &:hover {
      .services-list-item {
        &-title {
          color: var(--cl-primary);
          transform: scaleX(1.06);
          text-shadow: var(--cl-primary) 1px 1px;
        }
      }
    }
  }
}

.process-section {
  padding: ac(130px, 45px) 0 ac(40px, 20px);

  .title {
    font-size: ac(70px, 27px);
    line-height: 1.055;
    margin-bottom: ac(57px, 35px);
    max-width: 1030px;
  }

  &-wrapper {
    display: flex;
    gap: ac(50px, 30px);
    align-items: center;
    padding-bottom: ac(80px, 40px);
    border-bottom: 1px solid var(--cl-white);
  }

  &-thumbnail {
    width: ac(606px, 280px);
    max-width: 100%;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    &::before {
      content: '';
      display: block;
      padding-top: 47.35%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  &-description {
    flex: 1;
    font-family: var(--font-second);
    font-size: ac(25px, 20px);
    line-height: 1.302;
    font-weight: 400;

    p {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.steps-section {
  padding: ac(40px, 20px) 0;

  .title {
    font-size: ac(70px, 27px);
    line-height: 1.0555;
    margin-bottom: ac(44px, 35px);
    max-width: ac(712px, 400px);
    margin-left: auto;
    text-align: right;

    &.second-title {
      font-size: ac(50px, 24px);
      line-height: 1.3;
      margin-bottom: ac(44px, 35px);
    }
  }

  &-description {
    font-size: ac(17px, 15px);
    line-height: 1.302;
    margin-bottom: ac(60px, 35px);
    max-width: ac(712px, 400px);
    font-family: var(--font-second);
    margin-left: auto;
    text-align: right;
  }

  &-wrapper {
    padding-bottom: ac(79px, 40px);
    border-bottom: 1px solid var(--cl-white);
  }
}

.steps-swiper-wr {
  .swiper {
    overflow: visible;

    &-slide {
      display: flex;
      height: auto;
    }
    &-buttons {
      width: 100%;
      justify-content: center;
    }
    &-button {
      margin-top: 35px;
    }
  }
}

.steps-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--cl-white);
  border-radius: 10px;
  padding: ac(30px, 20px) ac(40px, 20px) ac(40px, 30px);
  transition: transform 0.3s;
  cursor: default;

  &:hover {
    transform: scale(1.05);
  }

  &-number {
    font-size: ac(50px, 30px);
    line-height: 1.302;
    color: var(--cl-primary);
    font-weight: 500;
    margin-bottom: 7px;
  }

  &-title {
    font-size: ac(25px, 17px);
    font-weight: 500;
    line-height: 1.302;
    padding-bottom: 19px;
    position: relative;
    margin-bottom: ac(62px, 30px);

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 25px;
      height: 3px;
      background: var(--cl-primary);
    }
  }

  &-description {
    --line-count: 7;
    --fz: ac(20px, 18px);
    --line-height: 1.302;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    font-family: var(--font-second);

    p {
      padding-bottom: 1px;
    }
  }
}

.extras-section {
  padding: ac(40px, 20px) 0 ac(130px, 45px);

  .title {
    margin-bottom: ac(84px, 35px);
    font-size: ac(70px, 27px);
    line-height: 1.055;
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: ac(44px, 20px) ac(28px, 20px);
  }

  &-item {
    display: flex;
    gap: ac(30px, 15px);
    align-items: flex-start;
    font-size: ac(18px, 16px);
    line-height: 1.302;
    font-family: var(--font-second);
    font-weight: 400;

    img {
      flex-shrink: 0;
      width: ac(34px, 24px);
      height: auto;
      margin-top: 6px;
    }

    h3 {
      font-family: var(--font-main);
      font-size: ac(23px, 20px);
      font-weight: 500;
      margin-bottom: 23px;
    }

    &-text {
      padding-right: ac(60px, 0px);
    }
  }

  .btn {
    margin-top: ac(59px, 35px);
  }
}

@media (max-width: 750px) {
  .brand-list {
    grid-template-columns: 1fr;
  }
  .talent-section-grid {
    grid-template-columns: 1fr;
  }
  .services-list-item-content {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
  .services-list-item {
    grid-template-columns: auto 1fr;
  }
  .process-section {
    &-wrapper {
      flex-direction: column;
    }
    &-thumbnail {
      width: 400px;
    }
  }
  .extras-section-grid {
    grid-template-columns: 1fr;
  }
}
