.job-details-page {
  overflow: visible !important;

  @media (max-width: 640px) {
    overflow: hidden !important;
  }
}

.section__job-vacancy {
  padding-top: ac(180px, 130px);

  .section__job-vacancy__title {
    font-family: var(--font-second);
    font-size: ac(18px, 16px);
    font-weight: 400;
    line-height: 1.302;
    color: var(--cl-primary);
  }

  .section__job-vacancy__heading {
    font-size: ac(70px, 37px);
  }

  .job-vacancy__conditions {
    padding: ac(25px, 15px) 0 ac(40px, 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1400px) {
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: ac(71px, 35px);
    }

    @media (max-width: 551px) {
      align-items: stretch;
      flex-direction: column;
    }
  }

  .job-vacancy__condition {
    display: flex;
    flex-direction: column;
    gap: ac(26px, 13px);
    padding-left: 15px;
    border-left: 1px solid var(--cl-gray);

    @media (max-width: 1024px) {
      max-width: 300px;
    }

    /* &:nth-child(2) {
      margin-left: -10px;

      @media (max-width: 600px) {
        margin-left: -7px;
      }
    }

    &:nth-child(3) {
      margin-left: 10px;

      @media (max-width: 600px) {
        margin-left: 0;
      }
    } */

    &__label {
      font-family: var(--font-second);
      font-size: ac(18px, 16px);
      font-weight: 400;
      line-height: 1.302;
      color: var(--cl-fog);
    }

    &__value {
      position: relative;
      font-size: ac(25px, 20px);
      font-weight: 500;
      line-height: 1.4;

      &::before {
        content: '';
        width: ac(25px, 18px);
        height: ac(3px, 2px);
        position: absolute;
        left: 0;
        top: ac(-17px, -10px);
        background-color: var(--cl-primary);
      }
    }
  }

  .job-vacancy__apply-back {
    margin-bottom: ac(60px, 30px);
    padding: ac(40px, 20px) 0;
    border-top: 1px solid var(--cl-fog);
    border-bottom: 1px solid var(--cl-fog);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .job-vacancy__back-link {
    display: flex;
    align-items: center;
    gap: ac(25px, 15px);
    font-size: ac(17px, 14px);
    font-weight: 500;
    line-height: 1.322;
    transition: all 0.3s ease;

    i {
      transition: all 0.3s ease;
    }

    &:hover {
      color: var(--cl-primary);

      i {
        transform: translateX(5px);
      }
    }
  }
}

.section__similar-jobs {
  padding-top: ac(120px, 53px);
  padding-bottom: ac(130px, 53px);

  .latest-jobs__title {
    font-size: ac(50px, 27px);
  }

  .latest-jobs__list {
    padding-top: 8px;
  }

  .job-line-block {
    margin-top: -5px;
    &:last-child {
      border-bottom: 0;
    }
  }
}

.job-details-page__ready {
  padding: ac(60px, 25px) 0;
  margin: 0 auto ac(20px, 14px);
  width: calc(100% - ac(60px, 20px));
  border-radius: 10px;
  .ready__content-wrapper {
    margin-left: ac(-27px, -10px);
    margin-right: ac(-27px, -10px);
  }

  &.job-details-page__ready--attention {
    margin-bottom: ac(80px, 40px);
    background-color: var(--cl-primary);
  }

  &.job-details-page__ready--attention .ready__title {
    margin-bottom: 0;
    max-width: ac(900px, 650px);
    width: 100%;
  }

  .ready__title {
    margin-bottom: ac(25px, 15px);
    max-width: 550px;
    width: 100%;
    font-size: ac(50px, 25px);
    line-height: 1.1;
  }

  .ready__description {
    max-width: 650px;
    width: 100%;
    font-family: var(--font-second);
    font-size: 17px;
    font-weight: 400;
    line-height: 1.302;

    @media (max-width: 1060px) {
      max-width: 450px;
    }
  }

  .ready__btn {
    background-color: var(--cl-gray);
  }

  .ready__content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  }
}

.section__job-details {
  .job-details__wrapper {
    display: flex;
  }

  .job-details__content {
    flex: 1;
    min-width: 0;
    padding-right: ac(69px, 30px);
    width: 100%;
    border-right: 1px solid var(--cl-fog);

    .content-elements {
      max-width: 818px;

      h2 {
        font-size: ac(30px, 25px);
        font-weight: 500;
        line-height: 1.166;
        margin-bottom: ac(20px, 10px);
      }

      h3 {
        font-size: ac(26px, 24px);
        font-weight: 500;
        line-height: 1.166;
        margin-bottom: ac(20px, 10px);
      }

      h4 {
        font-size: ac(24px, 22px);
        font-weight: 500;
        line-height: 1.166;
        margin-bottom: ac(20px, 10px);
      }

      p {
        font-family: var(--font-second);
        font-size: ac(17px, 15px);
        font-weight: 400;
        line-height: 1.302;
        margin: 0 !important;
        margin-bottom: 5px !important;

        span {
          margin: 0 !important;
          background: transparent !important;
          color: var(--cl-white) !important;
          font: inherit !important;

          b {
            display: block;
          }
        }

        + h2 {
          padding-top: ac(50px, 20px);
        }

        + h3 {
          padding-top: ac(50px, 20px);
        }

        + h4 {
          padding-top: ac(50px, 20px);
        }

        + ul {
          padding-top: ac(20px, 14px);
        }

        + ol {
          padding-top: ac(20px, 14px);
        }
      }

      strong {
        + ul {
          padding-top: ac(20px, 14px);
        }

        + ol {
          padding-top: ac(20px, 14px);
        }

        + h2 {
          padding-top: ac(50px, 20px);
        }

        + h3 {
          padding-top: ac(50px, 20px);
        }

        + h4 {
          padding-top: ac(50px, 20px);
        }
      }

      a {
        text-decoration: underline;
        transition: color 0.3s ease;

        &:hover {
          color: var(--cl-primary);
        }
      }

      br {
        + p {
          padding-top: ac(20px, 14px);
        }

        + ul {
          padding-top: ac(20px, 14px);
        }

        + ol {
          padding-top: ac(20px, 14px);
        }

        + h2 {
          padding-top: ac(50px, 20px);
        }

        + h3 {
          padding-top: ac(50px, 20px);
        }

        + h4 {
          padding-top: ac(50px, 20px);
        }
      }

      ul,
      ol {
        display: flex;
        flex-direction: column;
        gap: ac(10px, 5px);
        margin: 0 !important;
        margin-bottom: 10px !important;

        + h2 {
          padding-top: ac(50px, 20px);
        }

        + h3 {
          padding-top: ac(50px, 20px);
        }

        + h4 {
          padding-top: ac(50px, 20px);
        }
      }

      ul {
        li {
          position: relative;
          font-family: var(--font-second);
          font-size: ac(16px, 13px);
          font-weight: 400;
          line-height: 1.302;
          padding-left: ac(28px, 20px);

          span {
            margin: 0 !important;
            background: transparent !important;
            color: var(--cl-white) !important;
            font: inherit !important;
          }

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -60%);
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: var(--cl-primary);
          }
        }
      }

      ol {
        list-style-type: none;
        counter-reset: num;

        li {
          position: relative;
          display: inline-block;
          padding-left: ac(28px, 20px);

          &:before {
            position: absolute;
            color: var(--cl-white);
            top: 0;
            left: 0;
            font: inherit;
            content: counter(num) '.';
            counter-increment: num;
          }
        }
      }
    }

    .job-details__content__share {
      margin-top: ac(50px, 25px);
      display: flex;
      flex-direction: column;
      gap: 18px;

      .job-details__content__share__social-links {
        display: flex;
        align-items: center;
        gap: 13px;

        & > * {
          i {
            color: var(--cl-primary);
            transition: color 0.3s ease;
          }
          &:hover {
            i {
              color: var(--cl-white);
            }
          }
        }
      }

      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.323;
      }
    }
  }

  .job-details__contacts {
    padding-left: ac(67px, 37px);
    width: ac(355px, 320px);
    max-width: 100%;
    flex-shrink: 0;

    &__wrapper {
      position: sticky;
      top: 110px;
    }

    .job-details__contacts__img-wrapper {
      position: relative;
      overflow: hidden;
      border-radius: 10px;

      &::before {
        content: '';
        display: block;
        padding-top: 127%;
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
      }
    }

    h2 {
      margin-top: ac(25px, 18px);
      font-size: ac(30px, 25px);
      font-weight: 500;
      line-height: 1.166;
      margin-bottom: ac(20px, 10px);
    }

    p {
      font-family: var(--font-second);
      font-size: ac(18px, 13px);
      font-weight: 400;
      line-height: 1.302;

      &:first-of-type {
        font-size: ac(17px, 15px);
        font-weight: 500;
      }
    }

    .consultant-contacts {
      margin-top: ac(20px, 12px);
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .job-details__contacts__btn {
      display: inline-block;
      margin-top: ac(40px, 20px);

      .btn {
        border: 1px solid var(--cl-white);
        background-color: transparent;
        transition: all 0.3s ease;
        gap: 20px;
        padding: 14px 15px;
        min-height: 52px;

        &:hover {
          border-color: var(--cl-primary);
        }

        span {
          color: var(--cl-white);
        }
      }
    }
  }
}

.consultant-contact {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  i {
    color: var(--cl-primary);
  }

  span {
    font-size: ac(18px, 12px);
    font-family: var(--font-second);
    font-weight: 400;
    transition: color 0.3s ease;
  }

  &:hover {
    span {
      color: var(--cl-primary);
    }
  }
}

@media (max-width: 750px) {
  .section__job-details {
    .job-details__wrapper {
      flex-direction: column;
      gap: 35px;
    }

    .job-details__content {
      padding-right: 0;
      border: 0;
    }
    .job-details__contacts {
      margin: 0 auto;
      padding-left: 0;
    }
  }
}

@media (max-width: 450px) {
  .section__job-vacancy .job-vacancy__apply-back {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 30px;
  }
}

.gm-style-iw-d {
  overflow: visible !important;
}

.gm-style .gm-style-iw-c {
  padding: 12px 25px !important;
}

.gm-ui-hover-effect {
  width: 24px !important;
  height: 24px !important;
  margin-top: -12px !important;
  margin-right: -12px !important;

  span {
    margin: 0 !important;
  }
}
