.section__candidate-services-hero {
  padding-top: ac(180px, 100px);

  h1 {
    font-size: ac(70px, 45px);
    font-weight: 500;
    line-height: 1;
  }

  .candidate-services-hero__img-wrapper {
    margin-top: ac(100px, 40px);
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      padding-top: 34.723%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }
}

.section__candidate-services-why-us {
  padding-top: ac(148px, 50px);

  &.with-first {
    padding-top: ac(190px, 120px);
  }

  .section__candidate-services-why-us__wrapper {
    .section-label {
      margin-bottom: ac(17px, 12px);
      padding: 8px 18px;
      line-height: 1.3233;
    }

    h2 {
      font-size: ac(65px, 40px);
      font-weight: 500;
      line-height: 1.186;
    }

    .solutions-section-description {
      margin-top: ac(40px, 22px);
      margin-bottom: 0;

      p {
        word-spacing: -0.35px;
      }
    }
  }
}

.section__candidate-services-approach {
  padding-top: ac(130px, 50px);

  .section__candidate-services-approach__wrapper {
    display: flex;
    width: calc(100% - ac(60px, 40px));
    max-width: 1380px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;

    .section__candidate-services-approach__img-wrapper {
      position: relative;
      overflow: hidden;
      width: ac(569px, 500px);
      max-width: 100%;
      flex-shrink: 0;

      &::before {
        content: '';
        display: block;
        padding-top: 74.695%;
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
      }

      .mini-logo {
        position: absolute;
        top: ac(25px, 20px);
        left: ac(30px, 20px);
        width: 40px;
        height: auto;
        transform: none;
        z-index: 2;
      }
    }

    .section__candidate-services-approach__content {
      flex: 1;
      min-width: 0;
      width: 100%;
      padding: ac(60px, 25px) ac(69px, 30px);
      background-color: var(--cl-gray);

      h2 {
        @media (max-width: 1280px) {
          max-width: 80%;
        }

        @media (max-width: 768px) {
          max-width: 100%;
        }

        @media (max-width: 452px) {
          max-width: 70%;
        }

        @media (max-width: 374px) {
          max-width: 100%;
        }
      }

      .section-label {
        margin-bottom: ac(30px, 20px);
        padding: 8px 18px;
      }

      .solutions-section-description {
        margin-top: ac(40px, 25px);
        padding-top: ac(30px, 15px);
        border-top: 1px solid var(--cl-white);
        margin-bottom: 0;

        p {
          word-spacing: -0.3px;
        }
      }
    }
  }
}

.section__candidate-services__working-with-us {
  padding-top: ac(130px, 50px);

  .candidate-services__working-with-us__top {
    max-width: 700px;
    display: flex;
    flex-direction: column;
    gap: ac(44px, 22px);

    h2 {
      font-size: ac(70px, 45px);
      font-weight: 500;
      line-height: 1.055;
    }

    p {
      font-family: var(--font-second);
      font-size: ac(17px, 16px);
      font-weight: 400;
      line-height: 1.302;
      word-spacing: -0.25px;
    }
  }

  .candidate-services-swiper-wr {
    border-top: 1px solid var(--cl-white);
    border-bottom: 1px solid var(--cl-white);
    padding-top: 30px;
    display: flex;
    gap: 35px;
    padding-bottom: ac(77px, 40px);
    margin-top: ac(50px, 25px);
    position: relative;

    .swiper {
      flex: 1;
      min-width: 0;
      overflow: visible;
    }

    .swiper-buttons {
      .swiper-button {
        border-color: transparent;
        margin-top: ac(50px, 30px);

        &:hover {
          color: var(--cl-primary);
          border-color: var(--cl-primary);
        }
      }

      @media (min-width: 891px) {
        position: absolute;
        z-index: 1;
        right: 0;
        top: ac(-60px, -45px);
        transform: translateY(-100%);

        .swiper-button {
          margin-top: 0;
        }
      }
    }
  }

  .candidate-services-swiper-tabs {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: ac(495px, 280px);
    flex-shrink: 0;
    align-items: flex-start;
  }

  .candidate-services-swiper-tab-item {
    font-size: ac(25px, 20px);
    font-weight: 300;
    line-height: 1.2;
    opacity: 0.3;
    padding-left: ac(30px, 20px);
    position: relative;
    cursor: pointer;
    transition: 0.3s;

    &::before {
      content: '';
      display: block;
      width: 5px;
      height: 0;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 2.5px;
      transition: 0.3s;
    }

    &:hover {
      color: var(--cl-primary);
      opacity: 0.9;
    }

    &.active {
      opacity: 1;
      color: var(--cl-primary);
      font-size: ac(25px, 17px);

      &::before {
        background: var(--cl-primary);
        height: 100%;
      }
    }
  }

  .candidate-services-info-card {
    font-size: ac(17px, 16px);
    font-family: var(--font-second);
    line-height: 1.302;

    h3,
    h2 {
      font-size: ac(50px, 27px);
      font-weight: 500;
      font-family: var(--font-main);
      margin-bottom: ac(29px, 20px);
      line-height: 1.166;
    }

    h3 {
      font-size: ac(30px, 23px);
    }

    p {
      margin-bottom: 15px;
      word-spacing: -0.2px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    ul {
      display: flex;
      gap: 10px 20px;
      flex-wrap: wrap;

      li {
        font-size: 16px;
        line-height: 1.301;
        display: flex;
        gap: 5px;

        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          background: var(--cl-primary);
          border-radius: 50%;
          align-self: flex-start;
          position: relative;
          top: calc((16px * 1.301) / 2);
          transform: translateY(-50%);
        }
      }
    }
  }
}

.section__candidate-services-add {
  padding-top: ac(80px, 35px);
  padding-bottom: ac(130px, 60px);

  .candidate-services-add-wr {
    display: flex;
    flex-direction: column;
    gap: ac(60px, 25px);

    &__top {
      h2 {
        font-size: ac(50px, 28px);
        font-weight: 500;
        line-height: 1.055;
      }
    }

    &__main {
      display: flex;
      align-items: flex-start;
      gap: ac(50px, 20px);

      .candidate-services-add-wr-main__img-wr {
        position: relative;
        overflow: hidden;
        width: ac(500px, 440px);
        max-width: 100%;
        flex-shrink: 0;
        border-radius: 10px;

        &::before {
          content: '';
          display: block;
          padding-top: 103.2%;
        }

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.3s;
        }
      }

      .candidate-services-add-wr-main__logo {
        @mixin aspect-ratio 37.73, 40.29;
        width: ac(38px, 32px);
        z-index: 1;
        position: absolute;
        left: ac(25px, 20px);
        top: ac(25px, 20px);

        img {
          transform: none;
        }
      }

      .candidate-services-add-wr-main__content {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: ac(50px, 15px);
        row-gap: ac(40px, 15px);
        place-content: center;
        margin-top: 0;

        .add-services-card {
          max-width: 331px;
          width: 100%;
          min-height: auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .add-services-card__title {
            font-size: ac(28px, 20px);
            font-weight: 500;
            line-height: 1.166;
            /*padding-bottom: ac(30px, 20px);*/
            position: relative;

            /*&::before {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              width: 25px;
              height: 3px;
              background-color: var(--cl-primary);
            }*/

            @media (min-width: 1200px) {
              max-width: 90%;
            }

            &:not(:last-child) {
              margin-bottom: 19px;
            }
          }

          .add-services-card__bottom {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
          }

          .add-services-card__descr {
            position: relative;
            font-family: var(--font-second);
            font-weight: 400;
            font-size: ac(18px, 15px);
            line-height: 1.55;
            @mixin max-line-length 3;
          }

          .add-services-card_btn {
            margin-top: ac(15px, 10px);
            padding: 8px;
            border-radius: 50%;
            background-color: var(--cl-white);

            .icon-plus {
              width: 16px;
              aspect-ratio: 1;
              color: var(--cl-primary);
            }
          }

          .add-services-card__hidden-btn {
            position: relative;
            cursor: pointer;
            width: auto;

            @media (min-width: 1025px) {
              &:hover {
                .add-services-card__hidden-content {
                  opacity: 1;
                  pointer-events: auto;
                }
              }
            }

            @media (max-width: 1024px) {
              &.active {
                .add-services-card__hidden-content {
                  opacity: 1;
                  pointer-events: auto;
                }
              }
            }
          }

          &:nth-child(2n) {
            @media (max-width: 1350px) {
              @media (min-width: 1025px) {
                .add-services-card__hidden-content {
                  left: auto;
                  right: 16px;
                }
              }
            }
          }

          .add-services-card__hidden-content {
            padding: ac(30px, 24px) ac(30px, 20px);
            border-radius: 10px;
            box-shadow: 0px 0px 30px #00060e;
            width: ac(380px, 290px);
            position: absolute;
            left: 16px;
            bottom: 16px;
            opacity: 0;
            pointer-events: none;
            z-index: 1;
            transition: all 0.3s ease;
            background: var(--cl-navy);
          }

          .add-services-card__hidden-text {
            width: calc(100% + 10px);
            padding-right: 10px;
            font-size: ac(18px, 16px);
            line-height: 1.2;
            font-family: var(--font-second);
            color: var(--cl-white);
            max-height: calc((ac(18px, 16px) * 1.21) * 10.1);

            p {
              padding-bottom: 1px;
            }

            .simplebar-track.simplebar-vertical {
              width: 4px;
              background: var(--pp-light-gray);
              border-radius: 2px;

              .simplebar-scrollbar {
                background: var(--cl-primary);
                border-radius: 2px;

                &:before {
                  content: none;
                }
              }
            }
          }
        }

        @media (max-width: 1024px) {
          margin-top: 10px;
        }

        @media (max-width: 640px) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }
    }
  }
}

.submit-section {
  padding-bottom: ac(165px, 59px);

  .register-section-wrapper {
    .register-section-caption {
      .title {
        max-width: 350px;
      }
    }

    .register-section-form {
      margin-top: ac(30px, 15px);
    }

    .register-section-description {
      @media (max-width: 1240px) {
        max-width: 400px;
      }
    }
  }

  .section-bg {
    filter: blur(0px);
  }
}

section.candidate-services__ready {
  padding: ac(60px, 25px) 0;
  margin: ac(135px, 55px) auto ac(20px, 14px);
  width: calc(100% - ac(60px, 20px));
  border-radius: 10px;

  .ready__content-wrapper {
    margin-left: ac(-27px, -10px);
    margin-right: ac(-27px, -10px);
  }

  &.candidate-services__ready--attention {
    margin-top: 0;
    margin-bottom: ac(80px, 40px);
    background-color: var(--cl-primary);

    .ready__content-wrapper__left {
      max-width: 870px;

      @media (max-width: 1287px) {
        max-width: 550px;
      }

      @media (max-width: 850px) {
        max-width: 500px;
      }

      @media (max-width: 768px) {
        max-width: 450px;
      }
    }
  }

  &.candidate-services__ready--attention .ready__title {
    margin-bottom: 0;
    max-width: 900px;
    width: 100%;
  }

  .ready__title {
    margin-bottom: ac(25px, 15px);
    max-width: 800px;
    width: 100%;
    font-size: ac(50px, 25px);
    line-height: 1.1;
  }

  .ready__description {
    max-width: 700px;
    width: 100%;
    font-family: var(--font-second);
    font-size: ac(17px, 16px);
    font-weight: 400;
    line-height: 1.302;
  }

  .ready__btn {
    background-color: var(--cl-gray);
  }

  .ready__content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      @media (max-width: 1024px) {
        max-width: 500px;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  }
}

@media (max-width: 1024px) {
  .section__candidate-services-approach__wrapper {
    flex-direction: column;
    align-items: center;

    .section__candidate-services-approach__img-wrapper {
      img {
        border-radius: 10px;
      }
    }
  }

  .candidate-services-add-wr {
    &__main {
      flex-direction: column;
    }
  }
}

@media (max-width: 750px) {
  .candidate-services-swiper-wr {
    flex-direction: column;

    .swiper {
      min-height: 0;
      width: 100%;
    }
  }

  .swiper-buttons {
    margin: 0 auto;
  }

  .candidate-services-swiper-tabs {
    width: max-content !important;
    flex-direction: column;
    flex-wrap: wrap;
    align-self: center;
    justify-self: center;
    gap: 15px;
  }
}
