.section-job-search {
  padding-top: ac(180px, 100px);

  .section-job-search__top {
    h1 {
      font-size: ac(50px, 28px);
      font-weight: 500;
      line-height: 1;
    }

    .job-search__form {
      margin-top: ac(70px, 30px);
      position: relative;
      z-index: 1;
      display: flex;
      flex-wrap: wrap;
      column-gap: ac(10px, 8px);
      row-gap: ac(20px, 12px);
      margin-bottom: ac(50px, 40px);
      max-width: 100%;

      & > * {
        flex: 1;
        width: max-content;
      }

      .latest-jobs__form-btn {
        margin-left: auto;
        margin-right: ac(8px, 0px);
        @media (min-width: 1023px) {
          min-width: ac(310px, 215px);
        }

        @media (max-width: 551px) {
          min-width: 100%;
          width: 100%;
        }
      }

      .job-search__form-input {
        min-width: ac(468px, 430px, 1200);
        height: 55px;

        @media (max-width: 551px) {
          min-width: 100%;
          width: 100%;
        }
      }

      .job-search__form-select {
        flex-basis: 277px;
        height: 55px;

        &:first-of-type {
          flex: 1;
        }

        @media (max-width: 551px) {
          min-width: 100%;
          width: 100%;
        }
      }

      .job-search__form-range {
        flex-basis: 449px;
        z-index: -1;

        &.radius-type {
          flex-basis: 280px;
        }

        p {
          font-family: var(--font-second);
          font-size: ac(16px, 12px);
          font-weight: 400;
          line-height: 1.302;
        }

        .double-range-job-search {
          margin-top: ac(8px, 6px);
          position: relative;
          display: block;
          padding-right: 15px;
          height: 15px;
          border-radius: 12px;
          border: none;
          box-shadow: none;
          background: var(--cl-gray);

          .noUi-tooltip {
            transform: translate(-50%, 100%);
            left: 50%;
            bottom: -5px;
            display: block !important;
            background: transparent;
            font-family: var(--font-second);
            font-size: ac(14px, 12px);
            font-weight: 400;
            line-height: 1.302;
            color: var(--cl-white);
            padding: 0;
            border: none;
            border-radius: 4px;
            &.hidden {
              display: none !important;
            }
          }

          .noUi-connect {
            background: var(--cl-primary);
            margin-right: -4px;
          }

          .noUi-handle {
            position: absolute;
            top: 50%;
            right: -11px;
            background: var(--cl-primary);
            width: 15px;
            height: 15px;
            border: 0;
            cursor: pointer;
            border-radius: 50%;
            box-shadow: none;
            box-shadow: 0px 4px 4px 0px #00000040;

            &:before,
            &:after {
              content: none;
            }
          }

          @media (max-width: 551px) {
            min-width: 100%;
            width: 100%;
          }
        }

        .single-range {
          margin-top: ac(8px, 6px);
          position: relative;
          display: block;
          padding-right: 15px;
          height: 15px;
          border-radius: 12px;
          border: none;
          box-shadow: none;
          background: var(--cl-gray);

          .noUi-connect {
            background: var(--cl-primary);
            border-radius: 12px;
          }

          .noUi-handle {
            position: absolute;
            top: 50%;
            right: -11px;
            background: var(--cl-primary);
            width: 15px;
            height: 15px;
            border: 0;
            cursor: pointer;
            border-radius: 50%;
            box-shadow: none;
            box-shadow: 0px 4px 4px 0px #00000040;

            &:before,
            &:after {
              content: none;
            }
          }
        }

        .single-range-info {
          font-family: var(--font-second);
          font-size: ac(14px, 12px);
          font-weight: 400;
          line-height: 1.302;
          color: var(--cl-white);
        }
      }
    }
  }

  .section-job-search__jobs {
    .job-line-block {
      &:last-child {
        border-bottom: 0;
      }
    }

    .job-line-block__location {
      max-width: 200px;
      width: 100%;

      @media (max-width: 1370px) {
        max-width: 170px;
      }
    }

    .job-line-block__location,
    .job-line-block__salary {
      font-size: ac(18px, 12px);
    }

    .job-line-block__salary {
      margin-left: -10px;

      @media (max-width: 768px) {
        margin-left: 0;
      }
    }

    .job-line-block__btn {
      margin-bottom: 10px;
      background: transparent;
      border: 1px solid var(--cl-white);
      color: var(--cl-white);

      &:hover {
        background: var(--cl-white);
        color: var(--cl-primary);
      }
    }
  }

  .search-jobs__btn {
    font-size: ac(17px, 12px);
    padding: 9.5px 26.5px;
    margin: 0 auto;
    margin-top: ac(25px, 15px);
    user-select: none;
    cursor: pointer;
    transition: all 0.3s ease;
    color: var(--cl-white);

    &:hover {
      color: var(--cl-primary);
      border-color: var(--cl-primary);
    }
  }
}

.section-job-locations {
  padding-top: ac(130px, 60px);

  h2 {
    font-size: ac(50px, 28px);
    font-weight: 500;
    line-height: 1.1875;
  }

  .job-search-map {
    width: calc(100% - ac(60px, 30px));
    margin: ac(80px, 35px) auto 0;
    height: 500px;
    border-radius: 10px !important;
    overflow: hidden;
    clip-path: inset(0 0 0 0 round 10px);
    transform: translateZ(0);
    color: var(--cl-navy);

    iframe {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    #map {
      width: 100% !important;
      height: 100% !important;
      border-radius: 10px !important;
      overflow: hidden;
      clip-path: inset(0 0 0 0 round 10px);
      transform: translateZ(0);
    }
  }
}

.ready.job-search__ready {
  margin-top: ac(80px, 35px);

  .ready__title {
    margin-bottom: ac(25px, 15px);
    max-width: 500px;
    width: 100%;
    font-size: ac(50px, 25px);
    line-height: 1.1;

    @media (max-width: 1024px) {
      max-width: 450px;
    }
  }

  .ready__description {
    max-width: 650px;
    width: 100%;
    font-family: var(--font-second);
    font-size: 17px;
    font-weight: 400;
    line-height: 1.302;

    @media (max-width: 1024px) {
      max-width: 450px;
    }
  }
}

@media (max-width: 1024px) {
  .section-job-search .job-search__form {
    & > * {
      flex: 1 1 100%;
    }

    & > *:last-child {
      flex: 0 0 auto;
      align-self: center;
    }

    .job-search__form-input,
    .job-search__form-select,
    .job-search__form-range {
      min-width: auto;
      flex-basis: 100%;
    }

    .job-search__form-select {
      &:first-of-type {
        flex: 1 1 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .section-job-search .job-search__form {
    flex-direction: column;

    & > * {
      width: 100%;
      max-height: 62px;
    }

    .job-search__form-input,
    .job-search__form-select,
    .job-search__form-range {
      min-width: 100%;
      flex-basis: 100%;
    }

    .job-search__form-select,
    .job-search__form-input,
    .job-search__form-range {
      width: 100%;
    }
  }
}
