.services-section {
  position: relative;
  z-index: 30;
  padding-top: ac(58px, 48px) !important;
  padding-bottom: ac(60px, 40px) !important;
  overflow: visible;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: ac(818px, 640px);

    &:not(:last-child) {
      margin-bottom: ac(33px, 30px);
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(45px, 35px);
    }
  }

  &__big-text {
    font-size: ac(70px, 26px);
    font-weight: 500;
    line-height: 105.56%;

    span {
      color: var(--cl-primary);
    }

    &:not(:last-child) {
      margin-bottom: ac(44px, 30px);
    }
  }

  &__text {
    width: 100%;
    font-size: ac(30px, 20px);
    font-family: var(--font-main);
    font-weight: 500;
    line-height: 116.67%;

    &:not(:last-child) {
      margin-bottom: ac(50px, 40px);
    }
  }

  &__slider {
    padding-top: ac(105px, 100px);
    padding-bottom: ac(105px, 100px);
    overflow: visible;
    width: 100%;
    max-width: 100%;
    position: relative;
    z-index: 10;

    /*&:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      height: 1px;
      z-index: -1;
      background: var(--cl-fog);
      content: '';
      transform: translateY(-50%);

      @media (max-width: 1025px) {
        right: -100vw;
      }
    }*/

    &:not(.swiper-initialized) {
      display: none;
    }

    .swiper-slide {
      &.animated {
        &:after {
          width: calc(100% + 30px);
          opacity: 1;
        }

        .services-section__item-icon {
          animation: scaleUpServices 0.5s ease-out forwards;
          animation-delay: 0.25s;
        }

        .services-section__item-icon-second {
          animation: scaleUpServices 0.5s ease-out forwards;
          animation-delay: 0.35s;
        }

        .services-section__item-icons {
          &:after {
            transform: translateX(-50%) translateY(-50%);
            opacity: 1;
            height: 80px;
            transition-delay: 0.25s;
          }
        }
      }
    }

    @keyframes scaleUpServices {
      0% {
        transform: scale(0);
        opacity: 0;
      }
      50% {
        transform: scale(1.2);
        opacity: 1;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  &__slider-buttons {
    justify-content: flex-start;
    margin-left: 0 !important;

    .swiper-button {
      margin-bottom: ac(40px, 30px);
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    position: relative;
    z-index: 1;

    &:hover {
      z-index: 10;
    }

    &:after {
      position: absolute;
      left: 0;
      width: 0;
      opacity: 0;
      transition: 0.5s;
      top: 50%;
      height: 1px;
      z-index: -1;
      background: var(--cl-fog);
      content: '';
      transform: translateY(-50%);
    }

    @media (max-width: 451px) {
      justify-content: flex-start;
      padding-left: 20px;
    }
  }

  &__item-icons {
    width: 22px;
    height: 22px;
    position: relative;
    z-index: 5;

    &:after {
      top: 50%;
      width: 1px;
      background: var(--cl-fog);
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      opacity: 0;
      height: 0;
      content: '';
      z-index: -1;
      position: absolute;
      transition: 0.5s;
    }

    .services-section {
      &__item-icon,
      &__item-icon-second {
        position: absolute;
        z-index: 1;
      }

      &__item-icon {
        top: -19px;
        margin-top: -22px;
        /*transform: translateY(-100%);*/
      }

      &__item-icon-second {
        bottom: -19px;
        margin-bottom: -22px;
        /*transform: translateY(100%);*/
      }
    }
  }

  &__item-icon,
  &__item-icon-second {
    --size: 22px;
    width: var(--size);
    height: var(--size);
    background: rgba(226, 118, 0, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /*color: var(--cl-sun);*/
    cursor: pointer;
    transition: 0.3s;
    position: relative;
    opacity: 0;

    &:after {
      position: absolute;
      --size: 12px;
      width: var(--size);
      height: var(--size);
      background: var(--cl-primary);
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
    }

    /*&:hover {
      color: var(--cl-chery);
    }*/
  }

  &__item-text,
  &__item-text-second {
    font-size: ac(17px, 15px);
    line-height: 125%;
    font-weight: 500;
    text-align: center;
    position: absolute;
    top: -55px;
    transform: translateY(-100%) translateX(-50%);
    left: 50%;
    width: ac(110px, 90px);

    @media (max-width: 451px) {
      text-align: left;
      transform: translateY(-100%) translateX(0);
      left: 20px;
    }
  }

  &__item-text-second {
    top: auto;
    bottom: -55px;
    transform: translateY(100%) translateX(-50%);

    @media (max-width: 451px) {
      transform: translateY(100%) translateX(0);
    }
  }

  &__item-tooltip {
    position: absolute;
    top: 0;
    left: 0;
    width: 95vw;
    max-width: ac(354px, 290px);
    padding: ac(30px, 20px);
    background: var(--cl-gray);
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;
    z-index: 10;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 0px 0px 30px #06172e;

    &.active {
      opacity: 1;
      pointer-events: auto;
    }

    &.is-second-content {
      transform: translateY(80px);
    }

    @media (min-width: 1430px) {
      max-width: 380px;
    }
  }

  &__item-tooltip-second {
    display: none;
  }

  &__item-tooltip-title {
    font-size: ac(17px, 15px);
    line-height: 125%;
    font-weight: 500;
    position: relative;

    &:after {
      position: absolute;
      left: 0;
      bottom: ac(-16px, -14px);
      transform: translateY(100%);
      height: 3px;
      width: 25px;
      background: var(--cl-primary);
      content: '';
    }

    &:not(:last-child) {
      margin-bottom: ac(38px, 30px);
    }
  }

  &__item-tooltip-text {
    width: calc(100% + 10px);
    padding-right: 10px;
    max-height: calc((ac(18px, 16px) * 1.2) * 9.1);
    font-family: var(--font-second);
    line-height: 1.19;

    p {
      padding-bottom: 1px;
    }

    .simplebar-track.simplebar-vertical {
      width: ac(3px, 2px);
      background: var(--cl-fog);
      border-radius: 0;
      transition: opacity 0.3s ease;

      .simplebar-scrollbar {
        background: var(--cl-primary);
        border-radius: 0;

        &:before {
          content: none;
        }
      }
    }
  }

  @media (max-width: 390px) {
    .services-section {
      &__slider {
        max-width: 95%;
        margin-left: 0;
      }

      &__item-tooltip {
        width: 80vw;
      }
    }
  }
}
