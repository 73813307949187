.reviews {
  width: 100vw;
  overflow: hidden;

  padding-top: ac(118px, 70px);
  padding-bottom: ac(130px, 40px);

  color: var(--cl-navy);
  background: var(--cl-fog);

  .reviews-slider {
    .swiper-slide {
      height: auto;
    }
    .reviews-card {
      height: 100%;
    }
  }
}

.reviews__top-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;

  margin-bottom: ac(75px, 32px);
}

.reviews__title {
  font-size: ac(70px, 26px);
  font-weight: 500;
  line-height: 1.06;
  color: var(--cl-gray);
}

.reviews__btn {
  @mixin media 451 {
    width: 100%;
  }
}

.reviews-card {
  padding: ac(30px, 20px, 1024);
  border-radius: 10px;

  background: var(--cl-white);
  box-shadow: 0px 0px 10px 0px #0000001a;

  &__scroll {
    width: calc(100% + ac(21px, 12px)) !important;
    /* max-height: calc(ac(24px, 21px) * 8);*/
    --line-count: 8;
    --fz: ac(18px, 16px);
    --line-height: 1.28;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    font-family: var(--font-second);

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background-color: rgba(6, 23, 46, 0.2);
    }

    .simplebar-scrollbar::before {
      opacity: 1;
      inset: 0px;
      background-color: var(--cl-navy);
    }
  }

  &__description {
    padding-right: 8px;
  }

  &__top-wrap {
    display: flex;
    gap: ac(15px, 12px);

    margin-bottom: 11px;
  }

  &__img-wrap {
    flex-shrink: 0;

    width: 50px;
    height: 50px;

    border-radius: 999px;

    background: var(--cl-gray);
  }

  &__info-wrap {
    align-self: center;
  }

  &__name {
    color: var(--cl-navy);

    font-size: ac(17px, 16px);
    font-weight: 500;
    line-height: 1.56;
  }

  &__date {
    color: var(--cl-gray);

    font-size: 13px;
    font-weight: 500;

    opacity: 0.5;
  }

  &__logo {
    width: 22px;
    height: 22px;

    margin-left: auto;
    align-self: start;
  }

  &__rating {
    display: flex;

    margin-bottom: ac(20px, 8px);

    i {
      width: 19px;
      background: var(--cl-primary);
    }
  }
}

.reviews-slider__nav {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 12px;

  margin-top: 26px;

  @mixin media 769 {
    display: flex;
  }
}
