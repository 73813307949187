.section__news-article {
  padding-top: ac(180px, 100px);

  .news-article__intro {
    font-family: var(--font-second);
    font-size: ac(18px, 14px);
    font-weight: 400;
    line-height: 1.302;
    color: var(--cl-primary);
  }

  .news-article__title {
    margin-top: ac(26.5px, 20px);
    font-size: ac(50px, 28px);
    line-height: 1.2;
  }

  .news-article__date {
    margin-top: ac(40px, 15px);
    font-family: var(--font-second);
    font-size: ac(16px, 12px);
    font-weight: 400;
    line-height: 1.302;
    color: var(--cl-fog);
  }

  .news-article__img-wrapper {
    position: relative;
    overflow: hidden;
    width: calc(100% - ac(60px, 40px));
    margin: ac(40px, 20px) auto 0;
    border-radius: 10px;

    &::before {
      content: '';
      display: block;
      padding-top: 43.48%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }

    @media (min-width: 2100px) {
      width: calc(100% - ac(360px, 240px));
    }

    &:not(:last-child) {
      margin-bottom: ac(100px, 40px);
    }
  }

  .content-elements {
    max-width: 825px;
    margin: 0 auto;

    picture,
    img {
      margin-top: ac(80px, 40px);
    }

    picture {
      position: relative;
      display: block;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--cl-primary);
        opacity: 0.2;
        mix-blend-mode: soft-light;
        z-index: 1;
      }
    }

    img {
      aspect-ratio: 818/356;
      border-radius: 10px;
      z-index: 0;
    }

    h2 {
      font-size: ac(30px, 25px);
      font-weight: 500;
      line-height: 1.166;
      margin-bottom: ac(20px, 10px);
    }

    h3 {
      font-size: ac(26px, 24px);
      font-weight: 500;
      line-height: 1.166;
      margin-bottom: ac(20px, 10px);
    }

    h4 {
      font-size: ac(24px, 22px);
      font-weight: 500;
      line-height: 1.166;
      margin-bottom: ac(20px, 10px);
    }

    p {
      font-family: var(--font-second);
      font-size: ac(16px, 14px);
      font-weight: 400;
      line-height: 1.302;
      margin: 0 !important;
      margin-bottom: 15px !important;

      span {
        margin: 0 !important;
        background: transparent !important;
        color: var(--cl-white) !important;
        font: inherit !important;

        b {
          display: block;
          padding-bottom: ac(10px, 5px) !important;
        }
      }

      + h2 {
        padding-top: ac(20px, 16px);
      }

      + h3 {
        padding-top: ac(20px, 16px);
      }

      + h4 {
        padding-top: ac(20px, 16px);
      }

      + ul {
        padding-top: ac(20px, 14px);
      }
    }

    a {
      text-decoration: underline;
      transition: color 0.3s ease;

      &:hover {
        color: var(--cl-primary);
      }
    }

    ul,
    ol {
      display: flex;
      flex-direction: column;
      gap: ac(10px, 5px);
      margin: 0 !important;
      margin-bottom: 10px !important;

      + h2 {
        padding-top: ac(50px, 20px);
      }

      + h3 {
        padding-top: ac(50px, 20px);
      }

      + h4 {
        padding-top: ac(50px, 20px);
      }
    }

    ul {
      li {
        position: relative;
        font-family: var(--font-second);
        font-size: ac(16px, 13px);
        font-weight: 400;
        line-height: 1.302;
        padding-left: ac(28px, 20px);

        span {
          margin: 0 !important;
          background: transparent !important;
          color: var(--cl-white) !important;
          font: inherit !important;
        }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -60%);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: var(--cl-primary);
        }
      }
    }

    ol {
      list-style-type: none;
      counter-reset: num;

      li {
        position: relative;
        display: inline-block;
        padding-left: ac(28px, 20px);

        &:before {
          position: absolute;
          color: var(--cl-white);
          top: 0;
          left: 0;
          font: inherit;
          content: counter(num) '.';
          counter-increment: num;
        }
      }
    }

    a {
      display: inline;
      text-decoration: underline;
      transition: 0.3s;
      &:hover {
        color: var(--cl-primary);
      }
    }

    blockquote {
      position: relative;
      padding: ac(105px, 55px) ac(50px, 25px) ac(50px, 25px) ac(50px, 25px);
      border-radius: 10px;
      background-color: var(--cl-primary);
      width: calc(100% + ac(100px, 0px));
      margin-left: ac(-50px, 0px);
      font-size: ac(30px, 20px);
      font-weight: 500;
      line-height: ac(35px, 30px);
      color: var(--cl-navy);

      &::before {
        content: '';
        position: absolute;
        left: ac(50px, 25px);
        top: ac(50px, 25px);
        width: ac(64px, 42.67px);
        height: ac(23px, 15.33px);
        background: url(../../assets/img/quotes.svg) no-repeat center/cover;
      }

      &:not(:last-child) {
        margin-bottom: ac(30px, 24px);
      }
    }

    a {
      display: inline;
      text-decoration: underline;
      transition: 0.3s;
      &:hover {
        color: var(--cl-primary);
      }
    }
  }

  .news-article__content__share {
    max-width: 820px;
    margin: ac(65px, 25px) auto 0;
    display: flex;
    flex-direction: column;
    gap: 18px;

    .news-article__content__share__social-links {
      display: flex;
      align-items: center;
      gap: 13px;

      & > * {
        i {
          color: var(--cl-primary);
          transition: color 0.3s ease;
        }
        &:hover {
          i {
            color: var(--cl-white);
          }
        }
      }
    }

    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.323;
    }

    i {
      color: var(--cl-primary);
    }
  }
}

.section__more-news {
  padding-top: ac(130px, 65px);
  padding-bottom: ac(130px, 50px);

  h2 {
    font-size: ac(50px, 25px);
    font-weight: 500;
    line-height: 0.9;
  }

  .more-news-swiper-wr {
    margin-top: ac(60px, 30px);
    .swiper {
      overflow: visible;

      &-slide {
        overflow: visible;
        width: ac(394px, 320px);
        max-width: 100%;

        .news__card__img-wrapper {
          .mx-layer {
            border-radius: 10px;
          }

          img {
            border-radius: 10px;
          }
        }
      }
      &-buttons {
        width: 100%;
        justify-content: center;
      }
      &-button {
        margin-top: 35px;
      }
    }
  }
}
