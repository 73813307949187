.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 45px;
  flex-shrink: 0;
  width: fit-content;
  padding: 10px 22px;
  border-radius: 999px;
  background: transparent;
  color: var(--cl-white);
  font-size: ac(17px, 16px);
  font-weight: 500;
  line-height: 1.322;
  min-height: ac(62px, 52px);

  border: 3px solid var(--cl-white);

  transition: all ease 250ms;

  &:hover {
    background: var(--cl-white);
    color: var(--cl-navy);

    .btn__icon {
      translate: 25% 0;
      color: var(--cl-primary);
    }
  }

  &.primary {
    border-color: transparent !important;
    background: var(--cl-primary);
    color: var(--cl-white);

    &:hover {
      background: transparent;
      border-color: var(--cl-primary) !important;
      color: var(--cl-primary);
    }
  }

  &.navy {
    background: transparent !important;
    border-color: var(--cl-navy);
    color: var(--cl-navy);

    .btn__icon {
      color: var(--cl-navy);
    }

    &:hover {
      background: var(--cl-navy) !important;
      color: var(--cl-white);

      .btn__icon {
        color: var(--cl-primary);
      }
    }
  }

  &.gray {
    background: var(--cl-gray);
    color: var(--cl-white);

    &:hover {
      background: var(--cl-white);
      color: var(--cl-navy);
      border-color: var(--cl-navy) !important;

      .btn__icon {
        color: var(--cl-navy);
      }
    }
  }
}

.btn__icon {
  width: 24px;

  margin-top: -2px;
  margin-bottom: -2px;

  color: var(--cl-white);

  transition: translate ease 250ms;
}

.btn__text {
  font-family: var(--font-main) !important;
}

.btn-icon-only {
  display: flex;
  align-items: center;
  justify-content: center;

  width: ac(62px, 56px);
  height: ac(62px, 56px);

  border: 1px solid var(--cl-navy);
  border-radius: 999px;

  color: var(--cl-navy);
  background: var(--cl-white);

  transition: all ease 250ms;

  &:not([disabled]):hover {
    border-color: var(--cl-primary);
    color: var(--cl-primary);
  }

  &.primary {
    border-color: transparent;

    color: var(--cl-primary);

    &:hover {
      color: var(--cl-navy);
    }
  }

  &.transparent {
    border-color: var(--cl-white);

    color: var(--cl-white);
    background: transparent;
  }

  i {
    width: 24px;
  }
}

.swiper-buttons {
  width: fit-content;
  display: flex;
  gap: ac(30px, 15px);
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;

  .swiper-button {
    position: relative;
    transform: none;
    margin: 0;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    display: flex;
    width: 47px;
    height: 47px;
    justify-content: center;
    align-items: center;  
    color: var(--cl-navy);
    border: 1px solid transparent;
    border-radius: 50%;
    background: var(--cl-fog);
    transition: 0.3s;

    &-bordered {
      border-color: var(--cl-primary);
    }

    &::after {
      display: none;
    }

    &:hover {
      border-color: var(--cl-primary);
      color: var(--cl-primary);
    }

    &.swiper-button-lock {
      display: none;
    }
  }
}

.toggle-btn {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  position: relative;
  background: var(--cl-white);
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 1px;
    background: var(--cl-primary);
  }

  &::after {
    width: 1px;
    height: 16px;
  }

  &:hover {
    background: var(--cl-primary);

    &::before,
    &::after {
      background: var(--cl-white);
    }
  }

  &.active {
    background: var(--cl-primary);

    &::before {
      background: var(--cl-white);
    }

    &::after {
      opacity: 0;
    }

    &:hover {
      opacity: 0.9;
    }
  }

  &.sr-only:not(.active) {
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }
}

.border-btn {
  display: inline-flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  padding: 9px 24px;
  line-height: 1.3229411765;
  font-size: 17px;
  font-weight: 500;
  flex-shrink: 0;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid var(--cl-white);
  border-radius: 31px;
  font-family: var(--font-main) !important;

  &:hover {
    background: var(--cl-primary);
    color: var(--cl-white);
  }
}

.upload-btn-wr {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: var(--font-second);
  width: fit-content;
  max-width: 100%;
  cursor: pointer;
  transition: 0.3s;
  .upload-btn-span-label {
    font-size: ac(16px, 14px);
  }

  input {
    display: none !important;
    display: none !important;
  }

  .upload-btn-inner {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .upload-btn-accept {
    font-size: 14px;
  }

  .cv_file_name {
    font-size: ac(16px, 14px);
    line-height: 1;
    min-width: 0;

    @mixin max-line-length-one;
  }
  &:hover {
    color: var(--cl-fog);
  }
}

.upload-btn {
  display: inline-flex;
  align-items: center;
  padding: 7px 18px;
  border-radius: 31px;
  background: var(--cl-fog);
  color: var(--cl-navy);
  font-size: 20px;
  line-height: 1.302;
  font-family: var(--font-second);
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background: var(--cl-primary);
    color: var(--cl-white);
  }
}

.plus-btn {
  padding: 8px;
  border-radius: 50%;
  background-color: var(--cl-white);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;

  .icon-plus {
    width: 16px;
    height: 16px;
    aspect-ratio: 1;
    color: var(--cl-primary);
    transition: 0.3s;
  }

  &:hover {
    background-color: var(--cl-primary);

    i {
      color: var(--cl-white);
    }
  }
}
