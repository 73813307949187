.about-us {
  max-width: 100vw;

  padding: ac(120px, 56px) 0 ac(147px, 48px);

  @mixin media 769 {
    overflow: hidden;
  }

  &.join-team-page {
    padding-top: ac(130px, 56px);
    padding-bottom: ac(141px, 48px);

    .about-us__count-up-list {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .about-us__bottom-wrap {
      @media (min-width: 1400px) {
        width: calc(100% + 8px);
      }
    }

    .about-us__big-text {
      margin-bottom: ac(95px, 32px);
    }
  }
}

.about-us__title {
  margin-bottom: ac(45px, 24px);
}

.about-us__big-text {
  margin-bottom: ac(82px, 32px);

  font-size: ac(58px, 26px);
  font-weight: 500;
  line-height: 1.187;
  max-width: 850px;

  @media (max-width: 1024px) {
    max-width: 600px;
  }

  span {
    /* color: var(--cl-primary); */
  }

  @mixin media 769 {
    line-height: 1.15;
  }
}

.about-us__bottom-wrap {
  display: flex;
  gap: ac(30px, 16px);

  @mixin media 1025 {
    flex-direction: column;
  }

  /* @mixin media 769 {
    overflow: hidden;
  } */
}

.about-us__description {
  width: 100%;
  max-width: ac(394px, 200px, 1024);

  padding-top: ac(10px, 0px);

  @mixin media 1025 {
    max-width: unset;
  }

  * {
    font-family: var(--font-second);
    font-size: ac(17px, 15px);
    font-weight: 400;
    line-height: 1.3;
  }
}

.about-us__count-up-list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  width: 100%;
  height: fit-content;

  @mixin media 769 {
    display: none;
  }
}

.count-up-block {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  color: var(--cl-fog);

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }

  &:not(:last-child) {
    border-right: 1px solid var(--cl-gray);
  }
}

.count-up-block__value-wrap {
  position: relative;

  display: flex;

  padding-bottom: ac(19px, 17px);
  margin-bottom: 13px;

  font-size: ac(50px, 38px, 1024);
  font-weight: 500;
  line-height: 0.9;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;

    width: 25px;
    height: 3px;

    background: var(--cl-primary);
  }
}

.count-up-block__text {
  color: var(--cl-fog);

  font-family: var(--font-second);
  font-size: ac(18px, 16px, 1024);
  line-height: 1.3;
}

.count-up-block__suffix {
  &.ml {
    margin-left: ac(9px, 7px);
  }
}

.count-up-slider {
  display: none;

  max-width: 100%;

  @mixin media 769 {
    margin-top: 30px;
    display: block;
  }

  .swiper-slide {
    height: auto;

    &::after {
      content: '';
      position: absolute;
      top: -6.5px;
      bottom: -6.5px;
      left: calc(100% + 12px);

      width: 1px;
      /* height: 100%; */

      background: var(--cl-gray);
    }

    .count-up-block {
      display: grid;
      grid-template-rows: auto 1fr;
      height: 100%;
    }

    .count-up-block__text {
      display: flex;
      align-items: center;

      height: 100%;
    }
  }
}

.count-up-slider__nav {
  display: flex;
  gap: 12px;

  margin-top: 31px;
}
