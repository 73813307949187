.ready {
  padding: ac(60px, 43px) 0;

  color: var(--cl-navy);
  background: var(--cl-fog);
}

.ready__title {
  margin-bottom: ac(45px, 32px);

  //font-size: ac(70px, 38px);
  font-size: ac(50px, 28px);
  font-weight: 500;
  line-height: 1.06;
  color: var(--cl-gray);

  br {
    display: none;
  }

  @mixin media 551 {
    max-width: 14ch;

    line-height: 1.16;

    br {
      display: block;
    }
  }
}

.ready__btn {
  @mixin media 451 {
    width: 100%;
  }
}
