.hero {
  position: relative;
  overflow: hidden;

  padding: 100px 0 ac(55px, 40px);

  min-height: 500px;
  height: 100vh;

  &::before,
  &::after {
    content: '';
    position: absolute;
    inset: 0;
  }

  &::before {
    z-index: 1;
    background: rgba(0, 0, 0, 0.32);
  }

  &::after {
    z-index: 2;
    background: var(--cl-primary);
    opacity: 0.2;
    mix-blend-mode: soft-light;
  }

  @mixin media 1025 {
    max-height: 900px;
  }

  .cont {
    position: relative;
    z-index: 3;

    display: flex;
    flex-direction: column;
    justify-content: end;

    height: 100%;
  }

  &.join-team-page {
    .cont {
      justify-content: center;
    }

    &::before {
      z-index: 2;
    }

    &::after {
      z-index: 1;
    }
  }
}

.hero__bg {
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
}

.hero__title {
  margin-bottom: ac(39px, 16px);

  max-width: 887px;

  font-size: ac(70px, 38px);
  font-weight: 500;
  line-height: 0.95;

  @mixin media 769 {
    line-height: 1.16;
  }
}

.hero__description {
  margin-bottom: 40px;

  * {
    font-family: var(--font-second);
    font-size: 25px;
    font-weight: 400;
    line-height: 1.3;
    overflow: hidden;
  }

  p {
    display: inline-block;
    visibility: hidden;
  }

  @mixin media 769 {
    margin-bottom: 63px;
  }
}

.hero__description::after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-family: var(--font-second);
  font-size: 25px;
  font-weight: 400;
  line-height: 1.3;
  white-space: normal;
  word-break: break-word;
  clip-path: inset(0 100% 0 0);
  animation: typing 1.5s ease-in-out 0.5s forwards;

  @media (min-width: 768px) {
    animation-duration: 3s;
  }
}

.hero__btns {
  display: flex;
  gap: ac(30px, 16px);

  @mixin media 551 {
    flex-direction: column;

    .btn {
      width: 100%;
    }
  }
}

@keyframes typing {
  0% {
    clip-path: inset(0 100% 0 0);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}