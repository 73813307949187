.expertise {
  max-width: 100vw;

  .cont {
    display: flex;

    overflow: hidden;

    width: perc(1380);
    max-width: 1380px;
    height: ac(750px, 600px, 1024);
    padding-left: 0;
    padding-right: 0;

    border-radius: 10px;
    clip-path: inset(0 0 0 0 round 10px);
    transform: translateZ(0);

    background: var(--cl-gray);

    @mixin media 1025 {
      height: unset;
    }

    @mixin media 769 {
      flex-direction: column;
      width: 91.5%;
    }
  }

  &.join-team-page {
    margin-top: ac(88px, 20px);

    .expertise__logo {
      left: ac(30px, 18px, 1024);
      right: auto;
    }

    @media (min-width: 769px) {
      .cont {
        flex-direction: row-reverse;
        /*height: ac(810px, 620px, 1024);*/

        .expertise__right-wrap {
          margin-left: 0;
        }

        @media (max-width: 1025px) {
          height: unset;
        }
      }

      .expertise-slider__nav {
        left: auto;
        right: ac(30px, 12px);
        bottom: ac(60px, 12px);
      }
    }

    .expertise-slider__item {
      padding-top: ac(24px, 20px);
    }

    @media (max-width: 551px) {
      .expertise__right-wrap {
        height: 400px;
      }
    }

    .expertise-tabs__item-btn {
      --size: 32px;
      width: var(--size);
      height: var(--size);
      
      &:before {
        width: 50%;
        height: 1px;
      }
  
      &:after {
        width: 1px;
        height: 50%;
      }

      &:hover {
        background-color: var(--cl-primary);

        &:before {
          background: var(--cl-white);
        }

        &:after {
          background: var(--cl-white);
        }
      }
    }
  }
}

.expertise__left-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: ac(60px, 24px) ac(69px, 20px) ac(60px, 31px);

  max-width: ac(811px, 375px);

  @mixin media 769 {
    max-width: unset;
  }
}

.expertise__title {
  margin-bottom: ac(40px, 30px);
}

.expertise__big-text {
  margin-bottom: ac(50px, 32px);

  font-size: ac(50px, 26px);
  font-weight: 500;
  line-height: 0.9;

  + .expertise__text {
    margin-top: ac(-16px, -8px);
  }
}

.expertise__text {
  font-size: 17px;
  line-height: normal;
  font-family: var(--font-second);

  &:not(:last-child) {
    margin-bottom: ac(50px, 32px);
  }
}

.expertise-slider {
  max-height: 166px;

  clip-path: inset(-10px 0 -500% 0);

  @mixin media 1025 {
    max-height: unset;
    clip-path: inset(0 0 0 0);
  }

  .swiper-slide {
    transition: all ease 250ms;

    &.swiper-slide-active {
      .expertise-slider__item {
        border-color: var(--cl-white);
      }

      .expertise-slider__item-num {
        color: var(--cl-primary);
      }

      .expertise-slider__item-title {
        margin-bottom: 22px;
        opacity: 1;

        @mixin media 1025 {
          margin-bottom: 12px;
        }
      }

      .expertise-slider__item-description {
        max-height: var(--height);
        visibility: visible;
        opacity: 1;

        @mixin media 1025 {
          max-height: unset;
          visibility: unset;
          opacity: 1;
        }
      }
    }

    &.swiper-slide-prev {
      opacity: 0;
    }
  }
}

.expertise-slider__item {
  display: flex;
  gap: ac(88px, 20px, 1024);

  border-top: 1px solid var(--cl-navy);
  padding-top: ac(28px, 20px, 1024);
  padding-bottom: ac(50px, 0px);

  cursor: pointer;

  transition: border ease 250ms;

  &:hover {
    .expertise-slider__item-num {
      color: var(--cl-white);
    }

    .expertise-slider__item-title {
      opacity: 1;
    }
  }
}

.expertise-slider__item-num {
  color: var(--cl-navy);

  font-family: var(--font-second);

  transition: color ease 250ms;
}

.expertise-slider__item-title {
  font-size: ac(25px, 24px);
  font-weight: 300;
  line-height: ac(30px, 26px);

  opacity: 0.3;

  transition: all ease 250ms;

  @mixin media 1381 {
    line-height: 1.083;
  }

  @mixin media 1025 {
    margin-bottom: 12px;
  }
}

.expertise-slider__item-description {
  --height: 0px;

  max-height: 0;
  opacity: 0;
  visibility: hidden;

  transition: all ease 250ms;

  @mixin media 1025 {
    max-height: unset;
    visibility: unset;
    opacity: 1;
  }

  p,
  ul,
  ol {
    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  * {
    font-family: var(--font-second);
    font-size: ac(16px, 14px);
    font-weight: 400;
    line-height: 1.3;
  }

  ul {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(30px, 16px);
    grid-row-gap: ac(20px, 16px);

    li {
      position: relative;
      padding-left: ac(28px, 20px);

      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: ac(5px, 4px);
        --size: ac(10px, 8px);
        width: var(--size);
        height: var(--size);
        background: var(--cl-primary);
        border-radius: 50%;
      }
    }

    @media (max-width: 451px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}

.expertise-slider__mob-nav {
  display: none;
  gap: 12px;

  margin-top: 24px;

  @mixin media 1025 {
    display: flex;
  }
}

.expertise__right-wrap {
  position: relative;
  flex: 1;

  max-width: 570px;
  height: 100%;

  @media (min-width: 1026px) {
    margin-left: auto;
  }

  @mixin media 1025 {
    display: grid;
    max-width: unset;
    min-height: 100%;
    height: unset;
    /* width: 345px;
    height: unset;
    aspect-ratio: 345/260;
    max-height: 260px; */
  }

  @mixin media 769 {
    /* width: 345px; */
    /* max-width: 600px; */
    height: unset;
    /* aspect-ratio: 345/260; */
    height: ac(400px, 260px, 551, 769);
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 2;

    border-radius: 10px;

    background: var(--cl-primary);

    mix-blend-mode: soft-light;
    opacity: 0.2;
  }
}

.expertise-image-slider {
  overflow: hidden;

  height: 100%;

  border-radius: 10px;

  @mixin media 1025 {
    /* max-height: 400px; */
  }
}

.expertise-image-slider__item {
  height: 100%;
  width: 100%;
  object-fit: cover;
  /*object-position: right 17% top 0;*/
  border-radius: 10px;
  overflow: hidden;
}

.expertise-slider__nav {
  position: absolute;
  bottom: ac(30px, 24px);
  left: ac(30px, 12px);
  z-index: 2;

  display: flex;
  flex-direction: column;
  gap: ac(30px, 16px);

  @mixin media 1025 {
    display: none;
  }
}

.expertise-slider__btn {
  &.swiper-button-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.expertise__btn {
  position: absolute;
  bottom: ac(30px, 12px, 1024);
  right: ac(30px, 24px, 1024);
  z-index: 2;

  @mixin media 1025 {
    right: 50%;
    translate: 50% 0;

    width: calc(100% - 24px);

    .btn__text {
      font-size: 16px;
    }
  }

  @mixin media 769 {
    gap: 15px;
  }
}

.expertise__logo {
  position: absolute;
  top: ac(30px, 12px, 1024);
  right: ac(30px, 18px, 1024);
  z-index: 2;

  width: ac(39px, 23px, 1024);
}

.expertise-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: ac(50px, 24px);
}

.expertise-tabs-container {
  width: 100%;
  flex: 1;
  position: relative;

  &:before {
    z-index: 1;
    bottom: -1px;
    height: 11px;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba(51, 66, 79, 0) 0%,
      rgba(51, 66, 79, 1) 100%
    );
    content: '';
    position: absolute;
  }
}

.expertise-tabs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: 10px;
  max-height: 100%;
  min-height: 100%;
  margin-bottom: 10px;

  /*height: ac(750px, 600px, 1024);*/

  .simplebar-wrapper {
    flex: 1;
  }

  .simplebar-track.simplebar-vertical {
    width: 4px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;

    .simplebar-scrollbar {
      background: var(--cl-primary);
      border-radius: 4px;

      &:before {
        content: none;
      }
    }
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: 1px solid var(--cl-navy);
    transition: border-color 0.3s;

    &.active {
      border-color: var(--cl-white);

      .expertise-tabs {
        &__item-content {
          margin-top: ac(-10px, -6px);
          opacity: 1;
          max-height: 1000px;
          padding-bottom: ac(30px, 20px);
        }

        &__item-num {
          color: var(--cl-primary);
        }

        &__item-btn {
          &:after {
            opacity: 0;
            height: 2px;
          }
        }

        &__item-title {
          opacity: 1;
        }
      }
    }
  }

  &__item-top {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    padding-top: ac(30px, 20px);
    padding-left: ac(106px, 40px);
    padding-bottom: ac(30px, 20px);
    cursor: pointer;

    &:hover {
      .expertise-tabs {
        &__item-title {
          color: var(--cl-primary);
          opacity: 1;
        }
      }
    }
  }

  &__item-num {
    position: absolute;
    top: ac(30px, 20px);
    left: 0;
    color: var(--cl-navy);
    font-family: var(--font-second);
    transition: color ease 250ms;
  }

  &__item-title {
    font-size: ac(25px, 24px);
    font-weight: 300;
    line-height: ac(30px, 26px);
    opacity: 0.3;
    transition: all ease 250ms;
    padding-right: ac(60px, 50px);

    @media (max-width: 1381px) {
      line-height: 1.083;
    }
  }

  &__item-btn {
    --size: 32px;
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
    border: 1px solid var(--cl-white);
    background: var(--cl-white);
    transition: 0.3s;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: ac(25px, 20px);

    &:before,
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      content: '';
      background: var(--cl-primary);
      transition: 0.3s;
      z-index: 1;
    }

    &:before {
      width: 50%;
      height: 1px;
    }

    &:after {
      width: 1px;
      height: 50%;
    }

    &:hover {
      background-color: var(--cl-primary);

      &:before {
        background: var(--cl-white);
      }

      &:after {
        background: var(--cl-white);
      }
    }
  }

  &__item-content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    width: 100%;
    transition: 0.4s;
    padding-left: ac(106px, 40px);
    padding-bottom: 0;

    @media (max-width: 451px) {
      padding-left: 0;
    }
  }

  &__item-description {
    width: 100%;
    p,
    ul,
    ol {
      &:not(:last-child) {
        margin-bottom: ac(20px, 16px);
      }
    }

    * {
      font-family: var(--font-second);
      font-size: ac(16px, 14px);
      font-weight: 400;
      line-height: 1.3;
    }

    ul {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: ac(30px, 16px);
      grid-row-gap: ac(20px, 16px);

      li {
        position: relative;
        padding-left: ac(28px, 20px);

        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: ac(5px, 4px);
          --size: ac(10px, 8px);
          width: var(--size);
          height: var(--size);
          background: var(--cl-primary);
          border-radius: 50%;
        }
      }

      @media (max-width: 451px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
  }
}
