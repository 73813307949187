.latest-jobs {
  padding-top: ac(119px, 53px);
  padding-bottom: ac(122px, 104px);
}

.latest-jobs__title {
  margin-bottom: ac(56px, 32px);
  font-size: ac(70px, 26px);
}

.latest-jobs__form {
  position: relative;
  z-index: 1;

  display: flex;
  gap: ac(10px, 8px);

  margin-bottom: ac(57px, 40px);

  & > * {
    width: 100%;
  }

  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, 56px);
  }

  @mixin media 551 {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: repeat(3, 56px) 62px;
  }
}

.latest-jobs__form-input {
  /*&:first-child {
    min-width: ac(450px, 200px, 1024);
  }*/

  @media (max-width: 1430px) {
    @media (min-width: 1201px) {
      letter-spacing: -0.025em;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.latest-jobs__form-btn {
  gap: ac(45px, 15px, 1024);

  max-width: 215px;

  @mixin media 1025 {
    max-width: unset;
  }

  @mixin media 551 {
    margin-top: 6px;
  }
}

.job-line-block {
  display: flex;
  gap: 30px;

  width: 100%;

  padding: ac(32px, 24px) 0 ac(38px, 23px);

  border-top: 1px solid var(--cl-white);

  &:last-child {
    border-bottom: 1px solid var(--cl-white);
  }

  &:hover {
    .job-line-block__title,
    .job-line-block__location {
      color: var(--cl-primary);
    }
  }

  @mixin media 769 {
    align-items: end;
  }
}

.job-line-block__info-wrap {
  display: flex;
  gap: ac(30px, 12px);

  width: 100%;

  @mixin media 769 {
    flex-direction: column;
  }
}

.job-line-block__sector-title-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  max-width: ac(500px, 350px);
}

.job-line-block__sector {
  margin-bottom: ac(22px, 12px);

  color: var(--cl-primary);

  font-family: var(--font-second);
  font-size: ac(18px, 16px);
  font-weight: 400;

  @mixin media 769 {
    line-height: 1.3;
  }
}

.job-line-block__title,
.job-line-block__location,
.job-line-block__salary {
  margin-top: auto;

  font-size: ac(25px, 22px);
  font-weight: 500;
  line-height: 35px;
  transition: all ease 250ms;

  @mixin media 1025 {
    line-height: 1.27;
  }
}

.job-line-block__location {
  width: max-content;

  @mixin media 769 {
    width: unset;

    font-size: 18px;
    line-height: 1.444;
  }
}

.job-line-block__btn {
  flex-shrink: 0;

  margin-top: auto;
  margin-left: auto;

  width: ac(50px, 40px);
  height: ac(50px, 40px);

  /* @mixin media 769 {
    width: 48px;
    height: 48px;
  } */
}
