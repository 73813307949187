:root {
  --pp-main: #707070;
  --pp-main-rgb: 112 112 112;

  --pp-black: #000;
  --pp-black-rgb: 0 0 0;

  --pp-white: #ffffff;
  --pp-white-rgb: 255 255 255;

  --pp-dark-blue: #0e1127;
  --pp-dark-blue-rgb: 14 17 39;

  --pp-blue: #3ab4ff;
  --pp-blue-rgb: 58 180 255;

  --pp-dark-gray: #343434;
  --pp-dark-gray-rgb: 52 52 52;

  --pp-light-gray: #dce1e4;
  --pp-light-gray-rgb: 220 225 228;

  --pp-font-main: var(--font-main);
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;

  &.active {
    display: block;
  }

  &__close-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: -1;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

  &__block {
    --pp-default-translate-x-from: -50%;
    --pp-default-translate-x-to: -50%;

    --pp-default-translate-y-from: -50%;
    --pp-default-translate-y-to: -50%;

    --pp-opacity-from: 0;
    --pp-opacity-to: 1;

    background: var(--pp-white);

    position: absolute;
    top: 50%;
    left: 50%;

    width: 90%;
    max-width: ac(850px, 600px);
    padding: ac(40px, 30px) ac(40px, 24px);

    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    transition: all 300ms ease-out;
    animation-duration: 0.6s;

    &.without-opacity {
      --pp-opacity-from: 1;
    }

    &.pos-l,
    &.pos-r {
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;
    }

    &.pos-t,
    &.pos-b {
      --pp-default-translate-y-from: 0;
      --pp-default-translate-y-to: 0;
    }

    &.pos-t {
      top: 0;
      bottom: initial;
    }

    &.pos-r {
      right: 0;
      left: initial;
    }

    &.pos-b {
      top: initial;
      bottom: 0;
    }

    &.pos-l {
      right: initial;
      left: 0;
    }

    &.full-height {
      max-height: 100vh;
      height: 100%;
      right: 0;
      left: initial;
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;

      .popup__content {
        max-height: calc(var(--vh, 1vh) * 72);
      }
    }

    &.slide-l {
      --pp-default-translate-x-from: 100%;
    }

    &.slide-r {
      --pp-default-translate-x-from: -100%;
    }

    &.slide-t {
      --pp-default-translate-y-from: -100%;
    }

    &.slide-b {
      --pp-default-translate-y-from: 100%;
    }

    &.slide-t,
    &.slide-r,
    &.slide-b,
    &.slide-l {
      animation-name: popupSlide;
    }
  }

  &__title {
    color: var(--pp-dark-blue);
    font-size: ac(24px, 20px);
    line-height: 1.2;
    font-family: var(--pp-font-main);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: ac(32px, 24px);
  }

  &__btn-close {
    position: absolute;
    right: ac(30px, 20px);
    top: ac(30px, 10px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    --size: ac(32px, 28px);
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background: var(--cl-navy);

    .close-clip {
      transition: inherit;
      width: 16px;
      height: 16px;
      background: var(--cl-white);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: var(--cl-white);
        transition: all 0.3s ease;
      }
    }

    i {
      transition: inherit;
      font-size: 16px;
      color: var(--cl-white);
    }

    &:hover {
      background-color: var(--cl-primary);
      .close-clip {
        background: var(--cl-navy);
      }

      i {
        color: var(--cl-navy);
      }

      svg {
        path {
          color: var(--cl-navy);
        }
      }
    }
  }

  &__content {
    max-height: calc(var(--vh, 1vh) * 66);

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--pp-light-gray);
      border-radius: 4px;
      transform: translateX(10px);

      .simplebar-scrollbar {
        background: var(--pp-dark-blue);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    .popup-form {
      &__container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        @mixin max-sm {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }

      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        padding-top: ac(24px, 20px);
        flex-wrap: wrap;

        .popup-btn {
          margin-left: auto;
        }
      }
    }

    .popup-btn {
      font-size: ac(16px, 14px);
      font-weight: 700;
      font-family: var(--pp-font-main);
      line-height: 1.2;
      text-transform: uppercase;
      background-color: var(--pp-dark-blue);
      color: var(--pp-white);
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 12px 18px;
      cursor: pointer;
      transition: 0.3s ease-in-out all;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: skew(45deg);
        pointer-events: none;
      }

      &:hover {
        &::after {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.7) 100%
          );
          animation: gradientPopupBtn 0.3s linear forwards;
        }
      }

      &:active {
        transform: scale(0.9);
        opacity: 0.9;
      }
    }
  }

  &__block {
    &.project-popup {
      padding: 0;
      background: var(--cl-white);
      border-radius: 10px;
      box-shadow: 0px 0px 30px #00060e;
      width: 91%;
      max-width: 1242px;
      overflow: hidden;
      position: relative;

      &:after,
      &:before {
        position: absolute;
        left: 0;
        right: ac(6px, 4px);
        height: ac(20px, 8px);
        content: '';
        z-index: 10;
        pointer-events: none;
      }

      &:after {
        top: 0;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      &:before {
        bottom: 0;
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      .popup__content {
        width: 100%;
        padding: unset;
        max-height: calc(var(--vh, 1vh) * 90);

        .simplebar-track.simplebar-vertical {
          width: ac(6px, 4px);
          background: var(--cl-fog);
          border-radius: 4px;
          transform: none;

          .simplebar-scrollbar {
            background: var(--cl-primary);
            border-radius: 4px;

            &:before {
              content: none;
            }
          }
        }
      }

      &.popup-salary-guide {
        &.file-form-type {
          .project-popup {
            &__wrap {
              flex-direction: column;
              justify-content: flex-start;
              grid-row-gap: 30px;
            }

            &__form {
              width: 100%;
            }

            &__form-grid {
              display: grid;
              grid-template-columns: repeat(2, minmax(0, 1fr));

              @media (max-width: 640px) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
              }
            }
          }
        }

        .project-popup {
          &__wrap {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: row;
            padding: ac(83px, 50px) ac(82px, 20px);

            @media (max-width: 768px) {
              flex-direction: column;
              justify-content: flex-start;
              grid-row-gap: 30px;
            }
          }

          &__content {
            width: 56.27%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            @media (max-width: 801px) {
              width: 52%;
            }

            @media (max-width: 768px) {
              width: 100%;
            }
          }

          &__title {
            font-size: ac(40px, 30px);
            line-height: 112.5%;
            font-weight: 500;
            color: var(--cl-navy);

            span {
              color: var(--cl-primary);
            }

            &:not(:last-child) {
              margin-bottom: ac(34px, 24px);
            }
          }

          &__text {
            font-size: ac(20px, 16px);
            line-height: normal;
            font-family: var(--font-second);
            color: var(--cl-navy);

            p,
            ul,
            ol {
              &:not(:last-child) {
                margin-bottom: ac(20px, 14px);
              }
            }

            ul {
              width: 100%;
              display: grid;
              grid-template-columns: repeat(1, minmax(0, 1fr));
              grid-column-gap: ac(30px, 16px);
              grid-row-gap: ac(10px, 16px);

              li {
                position: relative;
                padding-left: ac(28px, 20px);
                font-family: var(--font-second);
                font-size: ac(16px, 14px);
                font-weight: 400;
                line-height: 1.3;

                &:before {
                  position: absolute;
                  content: '';
                  left: 0;
                  top: ac(5px, 4px);
                  --size: ac(10px, 8px);
                  width: var(--size);
                  height: var(--size);
                  background: var(--cl-primary);
                  border-radius: 50%;
                }
              }

              @media (max-width: 451px) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
              }
            }

            ol {
              li {
                font-family: var(--font-second);
                font-size: ac(16px, 14px);
                font-weight: 400;
                line-height: 1.3;
              }
            }
          }

          &__form {
            padding-top: ac(37px, 0px);
            width: 36.58%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            @media (max-width: 1024px) {
              width: 38%;
            }

            @media (max-width: 801px) {
              width: 42%;
            }

            @media (max-width: 768px) {
              width: 100%;
              max-width: 334px;
            }
          }

          &__form-title {
            font-size: ac(30px, 26px);
            line-height: 116.67%;
            font-weight: 500;
            color: var(--cl-navy);

            &:not(:last-child) {
              margin-bottom: ac(22px, 20px);
            }
          }

          &__form-grid {
            &.register-section-form {
              width: 100%;
            }
            .input-wr {
              span {
                color: var(--cl-navy);
              }

              input {
                background-color: var(--cl-fog);
              }
            }

            &:not(:last-child) {
              margin-bottom: ac(30px, 20px);
            }
          }

          &__form-buttons {
            .btn.navy {
              background-color: var(--cl-gray);
            }
          }
        }
      }

      &.popup-team-member {
        &.specialism-type {
          max-width: 700px;
          background: var(--cl-navy);

          &:after {
            top: 0;
            background: linear-gradient(
              180deg,
              rgba(6, 23, 46, 1) 0%,
              rgba(6, 23, 46, 0) 100%
            );
          }

          &:before {
            bottom: 0;
            background: linear-gradient(
              0deg,
              rgba(6, 23, 46, 1) 0%,
              rgba(6, 23, 46, 0) 100%
            );
          }

          .popup__btn-close {
            border: 1px solid var(--cl-primary);
          }

          .project-popup {
            &__text {
              color: var(--cl-white);
              ul,
              ol {
                display: flex;
                flex-direction: column;
                gap: ac(10px, 5px);
                margin: 0 !important;

                &:not(:last-child) {
                  margin-bottom: 10px !important;
                }
              }

              ul {
                li {
                  position: relative;
                  font-family: var(--font-second);
                  font-size: ac(16px, 13px);
                  font-weight: 400;
                  line-height: 1.302;
                  padding-left: ac(28px, 20px);

                  span {
                    margin: 0 !important;
                    background: transparent !important;
                    color: var(--cl-white) !important;
                    font: inherit !important;
                  }

                  &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate(0, -60%);
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: var(--cl-primary);
                  }
                }
              }

              ol {
                list-style-type: none;
                counter-reset: num;

                li {
                  position: relative;
                  display: inline-block;
                  padding-left: ac(28px, 20px);

                  &:before {
                    position: absolute;
                    color: var(--cl-white);
                    top: 0;
                    left: 0;
                    font: inherit;
                    content: counter(num) '.';
                    counter-increment: num;
                  }
                }
              }
            }
          }
        }

        .project-popup {
          &__wrap {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: row;
            padding: ac(83px, 50px) ac(82px, 20px);

            @media (max-width: 768px) {
              flex-direction: column;
              justify-content: flex-start;
              grid-row-gap: 30px;
            }
          }

          &__content {
            width: 56.27%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            @media (max-width: 801px) {
              width: 52%;
            }

            @media (max-width: 768px) {
              width: 100%;
            }
          }

          &__title {
            font-size: ac(36px, 30px);
            line-height: 112.5%;
            font-weight: 500;
            color: var(--cl-navy);

            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }

          &__position {
            font-size: ac(30px, 26px);
            line-height: 116.67%;
            font-weight: 500;
            color: var(--cl-navy);

            &:not(:last-child) {
              margin-bottom: ac(15px, 12px);
            }
          }

          &__subtitle {
            font-size: ac(25px, 20px);
            line-height: 140%;
            font-weight: 500;
            color: var(--cl-primary);

            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }

          &__text {
            padding-top: ac(19px, 10px);
            font-size: ac(20px, 16px);
            line-height: normal;
            font-family: var(--font-second);
            color: var(--cl-navy);

            blockquote {
              position: relative;
              padding: ac(105px, 55px) ac(50px, 25px) ac(50px, 25px)
                ac(50px, 25px);
              border-radius: 10px;
              background-color: var(--cl-primary);
              width: calc(100% + ac(100px, 0px));
              margin-left: ac(-50px, 0px);
              font-size: ac(30px, 20px);
              font-weight: 500;
              line-height: ac(35px, 30px);
              color: var(--cl-navy);

              &::before {
                content: '';
                position: absolute;
                left: ac(50px, 25px);
                top: ac(50px, 25px);
                width: ac(64px, 42.67px);
                height: ac(23px, 15.33px);
                background: url(../../assets/img/quotes.svg) no-repeat
                  center/cover;
              }

              &:not(:last-child) {
                margin-bottom: ac(28px, 20px);
              }
            }
          }

          &__card {
            width: 31.01%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            @media (max-width: 1024px) {
              width: 38%;
            }

            @media (max-width: 801px) {
              width: 42%;
            }

            @media (max-width: 768px) {
              width: 100%;
              max-width: 334px;
            }
          }

          &__card-img {
            @mixin aspect-ratio 334, 424;
            width: 100%;
            border-radius: 10px;
            overflow: hidden;

            &:after {
              position: absolute;
              inset: 0;
              background: rgba(226, 118, 0, 0.1);
              content: '';
              z-index: 1;
            }

            &:not(:last-child) {
              margin-bottom: ac(25px, 24px);
            }
          }

          &__card-socials {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .consultant-contact {
              span {
                color: var(--cl-navy);
              }

              &:hover {
                span {
                  color: var(--cl-primary);
                }
              }

              &:not(:last-child) {
                margin-bottom: ac(12px, 10px);
              }
            }

            &:not(:last-child) {
              margin-bottom: ac(40px, 24px);
            }
          }

          &__card-buttons {
            .btn.navy {
              background-color: var(--cl-gray);

              .btn__icon {
                color: var(--cl-primary);
                transition: all 0.3s ease;
                width: 21px;
              }

              &:hover {
                .btn__icon {
                  transform: none;
                  translate: 0;
                  color: var(--cl-white);
                }
              }
            }
          }
        }
      }
    }

    &.apply-now-popup {
      background: var(--cl-navy);
      border-radius: 10px;

      .simplebar-track.simplebar-vertical {
        width: 4px;
        background: var(--cl-gray);
        border-radius: 4px;
        transform: translateX(10px);
        top: 50px;

        .simplebar-scrollbar {
          background: var(--cl-primary);
          border-radius: 4px;

          &:before {
            content: none;
          }
        }
      }

      h3 {
        font-size: ac(35px, 22px);
        line-height: 140%;
        font-weight: 500;
        max-width: calc(100% - 64px);
      }

      .popup__content-container {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .popup__btn-close {
        background-color: var(--cl-primary);
        top: 0;
        right: 0;

        &:hover {
          background-color: var(--cl-gray);
        }
      }

      .register-section-form {
        width: 100%;

        &__grid {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-column-gap: ac(21px, 16px);
          grid-row-gap: 21px;

          @media (max-width: 690px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }
        }
      }
    }
  }
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from))
      translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }

  to {
    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}

@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }

  100% {
    transform: translateX(100%) skew(45deg);
  }
}
