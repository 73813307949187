.footer {
  padding: ac(70px, 43px) 0 20px;
  background: var(--cl-gray);

  @mixin media 1025 {
    padding-bottom: 40px;
  }
}

.footer__main {
  display: flex;
  justify-content: space-between;
  gap: ac(30px, 18px);

  padding-bottom: ac(63px, 24px);
  border-bottom: 1px solid var(--cl-fog);

  @mixin media 769 {
    flex-direction: column;
  }
}

.footer__start-block {
  max-width: ac(606px, 350px, 1024);

  @mixin media 769 {
    max-width: 400px;
  }
}

.footer__text {
  margin-bottom: ac(54px, 21px);

  font-size: ac(20px, 18px);
  font-weight: 500;
  line-height: 1.125;

  max-width: 80%;

  @mixin media 769 {
    line-height: 1.27;
  }
}

.footer__logo {
  width: 133px;

  transition: scale ease 250ms;

  &:hover {
    scale: 1.1;
  }

  @mixin media 769 {
    width: 186px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.footer__block-end {
  display: flex;
  justify-content: space-between;
  gap: ac(30px, 27px);

  width: 100%;
  max-width: 502px;

  padding-top: ac(3px, 0px, 1280);

  @mixin media 451 {
    flex-direction: column;
  }
}

.footer__block {
  display: flex;
  flex-direction: column;
  gap: 15px;

  min-width: 182px;

  @mixin media 769 {
    gap: 17px;
  }
}

.footer__block-title {
  font-size: ac(17px, 16px);
  font-weight: 700;
}

.footer__block-link-list {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px ac(60px, 40px);

  &.row {
    grid-template-columns: minmax(0, 1fr);

    @mixin media 451 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 23px 0px;
    }
  }

  @mixin media 451 {
    gap: 10px 0px;
  }
}

.footer__link {
  display: flex;
  align-items: center;
  gap: ac(14px, 9.5px);
  width: max-content;

  height: 22px;

  font-size: ac(17px, 16px);
  font-weight: 500;

  transition: color ease 250ms;

  @mixin media 1281 {
    height: unset;

    line-height: 1.125;
  }

  @mixin media 451 {
    width: fit-content;
  }

  &:hover {
    color: var(--cl-primary);
  }
}

.footer__link-icon {
  color: var(--cl-primary);

  @mixin media 769 {
    margin-left: 5px;
  }
}

.footer__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  padding-top: ac(20px, 16px);

  color: var(--cl-fog);

  font-family: var(--font-second);
  font-size: 17px;

  @mixin media 769 {
    padding-top: 23px;
  }
}

.footer__bottom-text {
  /* flex-shrink: 0; */

  @mixin media 551 {
    width: 100%;

    font-family: var(--font-second);
    font-size: 16px;
  }
}

.footer__bottom-links {
  display: flex;
  align-items: center;
  gap: 8px ac(30px, 24px);
  flex-wrap: wrap;

  @mixin media 551 {
    width: max-content;
    flex-direction: column;
    flex-shrink: 0;
    align-items: start;

    a {
      padding-right: 17px;
    }
  }

  a {
    font-size: ac(17px, 16px);
    font-weight: 400;

    transition: color ease 250ms;

    &:hover {
      color: var(--cl-primary);
    }
  }
}

#bold-credits {
  display: block;
  margin: 10px auto 0;

  transition: all ease 500ms;

  &:hover {
    scale: 1.05;
  }
}
